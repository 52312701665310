import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LogUtils } from '../../utils';
import { JsPluginLoaderService } from '../js-plugin-loader/js-plugin-loader.service';
declare var CortexDecoder;


@Injectable({
  providedIn: 'root'
})
export class CortexService {

  // symbologies enabled by default:
  // CortexDecoder.CDSymbology.Aztec
  // CortexDecoder.CDSymbology.Code39
  // CortexDecoder.CDSymbology.Code93
  // CortexDecoder.CDSymbology.Code128
  // CortexDecoder.CDSymbology.DataMatrix
  // CortexDecoder.CDSymbology.DotCode
  // CortexDecoder.CDSymbology.EAN8
  // CortexDecoder.CDSymbology.EAN13
  // CortexDecoder.CDSymbology.GS1Databar14
  // CortexDecoder.CDSymbology.Interleaved2of5
  // CortexDecoder.CDSymbology.PDF417
  // CortexDecoder.CDSymbology.QR
  // CortexDecoder.CDSymbology.UPCA
  // CortexDecoder.CDSymbology.UPCE

  private isCameraLoaded: boolean;
  private isLoaded: boolean;

  constructor(
    private jsPluginLoaderService: JsPluginLoaderService,
  ) {

  }

  init(licenseKey: string): Observable<any> {
    if (this.isLoaded) return of(null);

    const now = performance.now();
    return this.jsPluginLoaderService.load('cortex')
    .pipe(
      mergeMap(() => {
        return from(CortexDecoder.CDDecoder.init('../assets/js-plugins/cortex'));
      }),
      mergeMap(() => {
        CortexDecoder.CDDecoder.decoderTimeLimit = 100; // default is 50ms
        CortexDecoder.CDDecoder.setBarcodesToDecode(10, false);

        CortexDecoder.CDSymbology.Aztec.enable = false;
        CortexDecoder.CDSymbology.DataMatrix.enable = false;
        CortexDecoder.CDSymbology.DotCode.enable = false;
        CortexDecoder.CDSymbology.GS1Databar14.enable = false;
        CortexDecoder.CDSymbology.Interleaved2of5.enable = false;
        CortexDecoder.CDSymbology.PDF417.enable = false;
        CortexDecoder.CDSymbology.QR.enable = false;
        CortexDecoder.CDSymbology.UPCA.enable = false;
        CortexDecoder.CDSymbology.UPCE.enable = false;
        console.log(CortexDecoder.CDSymbology)

        return from(CortexDecoder.CDLicense.activateLicense(licenseKey));
      }),
      map((result: any) => {
        LogUtils.log(`CortexService.init() took: ${~~(performance.now() - now)}ms`, result);
        this.isLoaded = true;
      }),
    );
  }

  startCamera(): Observable<any> {
    const now = performance.now();
    return from(CortexDecoder.CDCamera.init())
    .pipe(
      mergeMap(() => {
        if (this.isCameraLoaded) return of(null);

        const cameras = CortexDecoder.CDCamera.getConnectedCameras() || [];
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
          return from(CortexDecoder.CDCamera.setCameraPosition('BACK'))
          .pipe(
            catchError((error: any) => {
              return from(CortexDecoder.CDCamera.setCamera(cameras[0].id));
            })
          );
        } else {
          const camera = cameras.find(c => (c.label?.toUpperCase() || '').indexOf('BACK') >= 0) || cameras[0];
          return from(CortexDecoder.CDCamera.setCamera(camera.id));
        }
      }),
      mergeMap(() => {
        if (this.isCameraLoaded) return of(null);

        return from(CortexDecoder.CDCamera.setResolution(this.getResolutionKey()));
      }),
      map(() => {
        this.isCameraLoaded = true;
        LogUtils.log(`CortexService.startCamera() took: ${~~(performance.now() - now)}ms`);
      })
    );
  }

  getResolutionKey(): string {
    const resolutions = Object.keys(CortexDecoder.CDResolution);
    return resolutions?.length > 1 ? resolutions[1] : resolutions[0];
  }

  getResolutionValue(): { width: number, height: number } {
    const resolutions = Object.values(CortexDecoder.CDResolution);
    return resolutions?.length > 1 ? resolutions[1] as any : resolutions[0] as any;
  }

  startVideoCapture(resultCallback: any): Observable<any> {
    const now = performance.now();
    return from(CortexDecoder.CDCamera.getPreview(resultCallback))
    .pipe(
      map((result) => {
        LogUtils.log(`CortexService.startVideoCapture() took: ${~~(performance.now() - now)}ms`);
      })
    );
  }

  stopVideoCapture(): Observable<any> {
    return from(CortexDecoder.CDCamera.stopPreview());
  }

}
