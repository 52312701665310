import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictString } from '../../dict.model';
import { RuntimeLayoutAction } from '../action/runtime-layout-action.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';
import { RuntimeLayoutControlCode } from './runtime-layout-control-code.enum';
import { SolutionDeviceControlScannerEnabledFlagType } from './solution-device-control-scanner-enabled-type.enum';
import { SolutionDeviceControlScannerTypes } from './solution-device-control-scanner-types.enum';


@Type.d('RuntimeLayoutControl')
export class RuntimeLayoutControl extends Message<RuntimeLayoutControl> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(50, 'int64', 'required')
  runtimeControlObjectId: number;
  @Field.d(51, 'string', 'required')
  solutionDeviceControlTypeGuidId: string;
  @Field.d(52, 'string', 'required')
  layoutControlCode: RuntimeLayoutControlCode;
  @MapField.d(53, 'string', RuntimeLayoutValue)
  renderValues: DictString<RuntimeLayoutValue>;
  @Field.d(55, RuntimeLayoutAction, 'repeated')
  actions: RuntimeLayoutAction[];
  /*@Field.d(70, 'bool', 'optional', false)
  backButton: boolean;
  @Field.d(71, 'bool', 'optional', false)
  forwardButton: boolean;*/
  @Field.d(72, 'int32', 'optional', 0)
  scannerEnabledType: SolutionDeviceControlScannerEnabledFlagType;
  @Field.d(73, 'int32', 'optional', 0)
  scannerTypes: SolutionDeviceControlScannerTypes;

  headDesignStyleObjectId: number; // only here to keep interface compatibility with layoutHead...remove when refactoring code

  parseRV(key: string, defaultValue?: any): any {
    let value = null;
    if (this.renderValues && this.renderValues[key]) {
      value = this.renderValues[key].parse();
    }
    return value != null ? value : defaultValue;
  }

}
