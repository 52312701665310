import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { LogUtils } from 'src/app/shared/utils';
import { KeyboardComponent } from '../components';




@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  private activeElement: HTMLInputElement;
  currentSelectionEnd: number;
  currentSelectionStart: number;
  private elementToOffsetQuerySelector: string;
  private keyboardComponent: KeyboardComponent;
  private keyboardQuerySelector: string;
  private platform: Platform;
  private subscriptions: Subscription[] = [];

  private keyPressCallback: Function;

  constructor() { }

  private unsubscribe() {
    for (const subscription of this.subscriptions || []) {
      subscription.unsubscribe();
    }
    this.subscriptions.length = 0;
  }

  init(platform: Platform, keyboardComponent: KeyboardComponent, elementToOffsetQuerySelector: string) {
    this.platform = platform;
    this.elementToOffsetQuerySelector = elementToOffsetQuerySelector;

    setTimeout(() => {
      this.unsubscribe();

      if (!keyboardComponent) {
        LogUtils.error('Failed to get the keyboard component');
        return;
      }

      this.elementToOffsetQuerySelector = elementToOffsetQuerySelector;

      this.keyboardComponent = keyboardComponent;
      this.keyboardQuerySelector = '#' + (keyboardComponent as any).el.nativeElement.children[0].id;

      this.subscriptions.push(
        this.keyboardComponent.afterShow.subscribe(this.onAfterShow.bind(this))
      );
      this.subscriptions.push(
        this.keyboardComponent.afterHide.subscribe(this.onAfterHide.bind(this))
      );
      this.subscriptions.push(
        this.keyboardComponent.keyClick.subscribe(this.onKeyClick.bind(this))
      );
    }, 10);
  }

  isVisible() {
    return this.keyboardComponent?.visible;
  }

  show(
    keyboardType: KeyboardType,
    useLanguageKeyboard: boolean,
    activeElement?: HTMLInputElement,
    keyPressCallback?: Function,
    uppercaseOnly?: boolean,
    startNumeric?: boolean,
  ) {
    if (activeElement) this.setActiveElement(activeElement, keyPressCallback);
    else this.keyPressCallback = keyPressCallback;

    this.keyboardComponent.uppercaseOnly = uppercaseOnly;
    this.keyboardComponent.show(keyboardType, useLanguageKeyboard, startNumeric);
  }

  getActiveElement() {
    return this.activeElement;
  }

  setActiveElement(activeElement?: HTMLInputElement, keyPressCallback?: Function) {
    this.activeElement = activeElement;
    this.keyPressCallback = keyPressCallback;

    this.updateInternalSelectionRange();
  }

  updateInternalSelectionRange() {
    if (!this.activeElement) return;

    this.currentSelectionStart = this.activeElement.selectionStart || 0;
    this.currentSelectionEnd = this.activeElement.selectionEnd || 0;
  }

  hide() {
    if (!this.keyboardComponent) return;
    this.keyboardComponent.hide();
  }

  private onKeyClick(key: string) {
    this.processKey(key);
  }


  processKey(key: string) {
    if (!this.activeElement) {
      if (this.keyPressCallback) this.keyPressCallback(key);
      return;
    }

    const currentScrollLeft = this.activeElement.scrollLeft;
    const currentScrollTop = this.activeElement.scrollTop;
    const value = this.activeElement.value;
    const selectionStart = this.activeElement.selectionStart;
    const selectionEnd = this.activeElement.selectionEnd;

    if (
      key === 'shift'
      || key === '123'
      || key === 'abc'
      || key === '!&'
      || key === ''
      || key === 'Enter'
    ) {
      // do nothing here. just reset focus (if on android) and call keyPressCallback
    } else if (key === 'Backspace') {
      if (selectionStart !== selectionEnd) { // delete selection. drag selecting on mobile doesn't update the this.currentSelectionStart/End
        this.activeElement.value = value.substring(0, selectionStart) + value.substring(selectionEnd);
        this.currentSelectionStart = selectionStart;
      } else { // delete char at caret
        this.currentSelectionStart = this.currentSelectionStart - 1;
        this.activeElement.value = value.substring(0, this.currentSelectionStart) + value.substring(this.currentSelectionEnd);
      }
    } else {
      if (selectionStart !== selectionEnd) { // overwrite selection. drag selecting on mobile doesn't update the this.currentSelectionStart/End
        this.activeElement.value = value.substring(0, selectionStart) + key + value.substring(selectionEnd);
        this.currentSelectionStart = selectionStart + 1;
      } else {
        this.activeElement.value = value.substring(0, this.currentSelectionStart) + key + value.substring(this.currentSelectionEnd);
        this.currentSelectionStart = this.currentSelectionStart + 1;
      }
    }

    this.currentSelectionStart = Math.max(0, this.currentSelectionStart); // don't let it be < 0
    this.currentSelectionEnd = this.currentSelectionStart;

    if (this.keyPressCallback) this.keyPressCallback(key);

    setTimeout(() => {
      if (this.platform.is('android')) this.activeElement.focus();

      setTimeout(() => {
        this.activeElement.setSelectionRange(this.currentSelectionStart, this.currentSelectionStart);
        this.activeElement.scrollLeft = this.currentSelectionStart >= this.activeElement.value?.length - 10 ? this.activeElement.scrollWidth : currentScrollLeft;
        this.activeElement.scrollTop = this.currentSelectionStart >= this.activeElement.value?.length - 10 ? this.activeElement.scrollHeight : currentScrollTop;
      }, 10);
    }, 10);
  }

  private onAfterShow(): void {
    const screen = document.querySelector(this.elementToOffsetQuerySelector) as HTMLElement;
    if (!screen) return;

    const page = screen.parentElement.parentElement as HTMLElement;
    if (!page) return;

    const keyboard = page.querySelector(this.keyboardQuerySelector) as HTMLElement;
    const keyboardHeight = ~~keyboard.getBoundingClientRect().height;

    const header = page.querySelector('ion-header') as HTMLElement;
    const headerHeight = ~~header.getBoundingClientRect().height;

    const footer = page.querySelector('ion-footer') as HTMLElement;
    const footerHeight = ~~footer.getBoundingClientRect().height;

    screen.style.height = `calc(100vh - ${headerHeight}px - ${footerHeight}px - ${keyboardHeight}px)`;
    setTimeout(() => {
      if (screen.scrollHeight > screen.clientHeight) { // if it has scrollbars...
        this.activeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }, 10);
  }

  private onAfterHide() {
    const screen = document.querySelector(this.elementToOffsetQuerySelector) as HTMLElement;
    if (!screen) return;

    screen.removeAttribute('style');
  }

}
