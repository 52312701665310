import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelectPrinterPopover } from './select-printer.popover';



@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    SelectPrinterPopover,
  ],
  entryComponents: [
    SelectPrinterPopover
  ]
})
export class SelectPrinterPopoverModule { }
