import { NgModule } from '@angular/core';
import { ConfirmPopoverModule } from 'src/app/popovers/confirm/confirm.popover.module';
import { LoadingModule } from 'src/app/shared/components/ngx-loading';
import { SharedModule } from 'src/app/shared/shared.module';
import { BluetoothModal } from './bluetooth.modal';



@NgModule({
  declarations: [
    BluetoothModal,
  ],
  entryComponents: [
    BluetoothModal,
  ],
  imports: [
    ConfirmPopoverModule,
    LoadingModule,
    SharedModule,
  ],
})
export class BluetoothModalModule { }
