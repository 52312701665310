import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Observer, Subscription, from, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap } from 'rxjs/operators';
import { BluetoothDevice, BluetoothDeviceMode, BluetoothDeviceType } from '../../../models/bluetooth-device.model';
import { BrowserUtils } from '../../../utils';
import { LocalSettingsService } from '../../local-settings/local-settings.service';
import { SetSettingsService } from '../../protobuf/set-settings.service';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';
declare const cordova;


export interface BluetoothSerialPluginSettings {
  retryIntervalInMs: number;
  retryAttempts: number;
  readDelimiter: string;
  writeChunkSize: number;
}


@Injectable({
  providedIn: 'root'
})
export class BluetoothSerialPlugin extends BasePlugin {

  private readonly connectTimeoutInMS = 15 * 1000;
  private readonly printerSatoTimeoutInMS = 5 * 1000;
  private readonly writeDelayInMS = 250;

  private settings: BluetoothSerialPluginSettings;

  private defaultPluginInstance: string;
  private deviceToPluginInstanceMap: { [deviceId: string]: string };
  private deviceToStartSubscriptionMap: { [deviceId: string]: string };
  private connectedDevices: BluetoothDevice[];
  private inactiveTimeout: any;
  private isPluginAllowedChecked: boolean;
  private pluginInstances: string[];
  private printerSatoResponse: string = '';
  private printerSatoSeq: number = 0;
  private printerSatoTimeout: any;
  private printerSatoWriteObserver: Observer<any>;

  // no longer using BluetoothSerial directly as to support multiple connections I had to use a HACK
  // described here: https://github.com/don/BluetoothSerial/issues/58
  constructor(
    // private bluetoothSerial: BluetoothSerial,
    injector: Injector,
    private localSettingsService: LocalSettingsService,
    private platform: Platform,
    private scannerService: ScannerService,
    private setSettingsService: SetSettingsService,
  ) {
    super(injector);

    this.pluginName = 'BtSerialPlugin';

    this.settings = {
      retryIntervalInMs: 5000,
      retryAttempts: 3,
      readDelimiter: undefined,
      writeChunkSize: 0, // 0 -> send it all in one go...
    };

    this.deviceToPluginInstanceMap = {};
    this.deviceToStartSubscriptionMap = {};

    this.defaultPluginInstance = this.platform.is('ios') ? 'BluetoothSerial' : 'BluetoothSerial1';
    this.pluginInstances = [
      'BluetoothSerial1',
      'BluetoothSerial2',
      'BluetoothSerial3',
    ];
  }

  isPluginAllowed(): boolean {
    return BrowserUtils.isDeviceApp() && this.platform.is('android');
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);

    return this.initAndHandleConnections(this.connectedDevices, options?.forceEnable || this.connectedDevices.length > 0);
  }

  private initAndHandleConnections(connectedDevices: BluetoothDevice[], forceEnable: boolean): Observable<void> {
    return this.checkAndHandleEnabledState(forceEnable)
    .pipe(
      mergeMap((isEnabled: boolean) => {
        if (isEnabled && connectedDevices?.length > 0) this.checkAndHandleConnectedState(connectedDevices);

        return of(null);
      })
    );
  }

  private checkAndHandleEnabledState(forceEnable: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.log('Checking BT enabled state...');

      cordova.exec(
        () => {
          this.log('Enabled');
          observer.next(true);
          observer.complete();
        },
        (error: any) => {
          this.log(error);
          if (forceEnable) {
            this.enable(observer);
            return;
          }

          observer.next(false);
          observer.complete();
        },
        this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
        'isEnabled',
        []
      );
    });
  }

  private enable(observer: Observer<boolean>) {
    this.log('Enabling BT...');

    cordova.exec(
      (success: any) => {
        this.log(success);
        observer.next(true);
        observer.complete();
      },
      (error: any) => {
        this.log(error);

        setTimeout(() => {
          this.enable(observer);
        }, this.settings.retryIntervalInMs);
      },
      this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
      'enable',
      []
    );
  }


  private checkAndHandleConnectedState(connectedDevices: BluetoothDevice[]) {
    this.log('Checking BT devices connected state...');
    for (const device of connectedDevices || []) {
      // Iterate over all the known devices and check if any of them is connected.
      // If yes, disconnect. If any of them is a scanner, connect to it.
      cordova.exec(
        (success: any) => {
          this.log(`Connected, but shouldn't be. Disconnecting...`);

          device.isConnected = false;
          this.disconnect(device, false)
          .subscribe(() => {
            this.log(`Disconnected from '${device.id}', as it should be at this point.`);

            this.ifAlwaysOnDeviceTryToConnect(device);
          }, (error: any) => {
            this.log(`Error trying to disconnect from '${device.id}': ` + JSON.stringify(error));

            this.ifAlwaysOnDeviceTryToConnect(device);
          });
        },
        (error: any) => {
          this.log(`Disconnected from '${device.id}', as it should be at this point.`);

          this.ifAlwaysOnDeviceTryToConnect(device);
        },
        this.defaultPluginInstance,
        'isConnected',
        []
      );
    }
  }

  private ifAlwaysOnDeviceTryToConnect(device: BluetoothDevice) {
    if (device.type === BluetoothDeviceType.Scanner) {
      this.log(`Trying to connect to '${device.id}' (scanner)...`);
      this.connect(device, false, true).subscribe();
    }
  }

  private connect(device: BluetoothDevice, firstTime: boolean, skipSaveSettingRemotely?: boolean): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const pluginInstance = this.getPluginInstanceAvailableForConnection(device);
      if (!pluginInstance) {
        observer.error(`You've reached the max number of allowed BT Classic connections: ${this.pluginInstances.length}`);
        return;
      }

      this.log(`Connecting to '${device.id}' on pluginInstance '${pluginInstance}'...`);
      this.deviceToPluginInstanceMap[device.id] = pluginInstance;

      if (!(device as any).$timeout) {
        (device as any).$timeout = setTimeout(() => {
          if ((device as any).$timeout) {
            this.log(`Connection timed-out for '${device.id}' on pluginInstance '${pluginInstance}'...`);
            this.disconnect(device, false)
            .subscribe(() => {
              delete (device as any).$timeout;
              this.failedConnectionHandler(device, firstTime, observer, new Error('Connection Timeout'));
            }, (error: any) => {
              delete (device as any).$timeout;
              this.failedConnectionHandler(device, firstTime, observer, error);
            });
          }
        }, this.connectTimeoutInMS);
      }

      cordova.exec(
        (success: any) => {
          if ((device as any).$timeout) {
            clearTimeout((device as any).$timeout);
            delete (device as any).$timeout;
          }
          firstTime = false; // this is used on (error) which gets called on failing to connect but also when/if the connection drops later

          this.log(`Connected to '${device.id}' on pluginInstance '${pluginInstance}'.`);

          device.shouldBeConnected = true;
          device.type = device.type || BluetoothDeviceType.Unknown;
          this.localSettingsService.addOrUpdateBtDevice(device);

          device.isConnected = true;
          this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);

          if (!skipSaveSettingRemotely) {
            device.setSettingRemotely(this.setSettingsService);
          }

          observer.next(null);
          observer.complete();
        },
        (error: any) => {
          this.log(`Failed to connect to '${device.id}' on pluginInstance '${pluginInstance}': ${JSON.stringify(error)}`);

          this.failedConnectionHandler(device, firstTime, observer, error);
        },
        pluginInstance,
        'connect',
        [device.id]
      );
    });
  }

  private failedConnectionHandler(device: BluetoothDevice, firstTime: boolean, observer: Observer<any>, error: any) {
    if ((device as any).$timeout) {
      clearTimeout((device as any).$timeout);
      delete (device as any).$timeout;
    }

    this.resetPluginInstance(device);
    device.isConnected = false;

    if (!firstTime && device.type === BluetoothDeviceType.Scanner) {
      setTimeout(() => {
        this.connect(device, false, false)
        .subscribe(() => {
          observer.next(null);
          observer.complete();
        }, (error: any) => {
          observer.error(error);
        });
      }, this.settings.retryIntervalInMs);
    } else {
      observer.error(error);
    }
  }

  private getPluginInstanceAvailableForConnection(device: BluetoothDevice): string {
    if (this.deviceToPluginInstanceMap[device.id]) {
      return this.deviceToPluginInstanceMap[device.id];
    }
    if (this.pluginInstances.length) {
      return this.pluginInstances.splice(0, 1)[0];
    } else {
      return null;
    }
  }

  private resetPluginInstance(device: BluetoothDevice) {
    const pluginInstance = this.deviceToPluginInstanceMap[device.id];
    if (pluginInstance) {
      this.pluginInstances.push(pluginInstance);
      delete this.deviceToPluginInstanceMap[device.id];
    }

    const startSubscription = this.deviceToStartSubscriptionMap[device.id];
    if (startSubscription) {
      delete this.deviceToStartSubscriptionMap[device.id];
    }
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    if (options.command !== 'list' && options.command !== 'discover' && this.inactiveTimeout) {
      clearTimeout(this.inactiveTimeout);
      this.inactiveTimeout = undefined;
    }

    this.log(`Action '${options.command}' started: ${JSON.stringify(options || {}, null, 1)}`);
    switch(options.command) {
      case 'list':
        if (this.platform.is('ios')) {
          return of([]); // Not supported on ios
        } else {
          return new Observable((observer: Observer<BluetoothDevice[]>) => {
            cordova.exec(
              (devices: BluetoothDevice[]) => {
                const knownDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);

                devices = (devices || []);
                devices.push(...knownDevices);

                devices = BluetoothDevice.removeDuplicatesDevices(devices)
                .map((d: BluetoothDevice) => {
                  d.mode = BluetoothDeviceMode.Classic;
                  const knownDevice = this.getKnownDevice(d);
                  if (knownDevice) {
                    knownDevice.shouldBeConnected = true;
                    return knownDevice;
                  } else {
                    d.shouldBeConnected = !!knownDevice;
                    d.isConnected = !!knownDevice ? knownDevice.isConnected : false;
                    d.type = !!knownDevice ? knownDevice.type : BluetoothDeviceType.Unknown;
                    return new BluetoothDevice(d);
                  }
                });

                if (this.localSettingsService.get().runDeviceDebug) {
                  this.log(`Action 'list' found ${devices.length} devices: ${JSON.stringify(devices)}`);
                } else {
                  this.log(`Action 'list' found ${devices.length} devices.`);
                }

                observer.next(devices);
                observer.complete();
              },
              (error: any) => {
                this.log(error);

                observer.next(this.connectedDevices);
                observer.complete();
              },
              'BluetoothSerial1', // Always use first plugin instance to perform non device / connection specific calls
              'list',
              []
            );
          });
        }
      case 'discover':
        if (this.platform.is('ios')) {
          return of([]); // Not supported on ios
        } else {
          return new Observable((observer: Observer<BluetoothDevice[]>) => {
            cordova.exec(
              (devices: BluetoothDevice[]) => {
                devices = BluetoothDevice.removeDuplicatesDevices(devices)
                .map((d: BluetoothDevice) => {
                  d.mode = BluetoothDeviceMode.Classic;
                  const knownDevice = this.getKnownDevice(d);
                  if (knownDevice) {
                    knownDevice.shouldBeConnected = true;
                    return knownDevice;
                  } else {
                    d.shouldBeConnected = !!knownDevice;
                    d.isConnected = !!knownDevice ? knownDevice.isConnected : false;
                    d.type = !!knownDevice ? knownDevice.type : BluetoothDeviceType.Unknown;
                    return new BluetoothDevice(d);
                  }
                });

                if (this.localSettingsService.get().runDeviceDebug) {
                  this.log(`Action 'discover' found ${devices.length} devices: ${JSON.stringify(devices)}`);
                } else {
                  this.log(`Action 'discover' found ${devices.length} devices.`);
                }
                observer.next(devices);
                observer.complete();
              },
              (error: any) => {
                this.log(error);
                observer.error(error);
              },
              'BluetoothSerial1', // Always use first plugin instance to perform non device / connection specific calls
              'discoverUnpaired',
              []
            );
          });
        }
      case 'connect':
        return this.actionConnect(options)
        .pipe(
          mergeMap((response: any) => {
            if (options.device.type !== BluetoothDeviceType.PrinterSato) return of(null);

            // For PrinterSato we request the printer information after connect and before doing anything else...
            return of(null)
            .pipe(
              delay(BluetoothDevice.TimeoutAfterConnect),
              map(() => {
                this.start(
                  (data: string) => {
                    this.handlePrinterSatoResponses(options.device, data);
                  }, {
                    device: options.device,
                  }
                );
              }),
              delay(BluetoothDevice.TimeoutAfterConnect),
              mergeMap(() => {
                return this.action({
                  command: 'write',
                  device: options.device,
                  messageType: 2,
                  writeData: undefined,
                })
              })
            );
          }),
          delay(BluetoothDevice.TimeoutAfterConnect), // give it an extra second to REALLLY connect to the printer
        );
      case 'disconnect':
        return this.disconnect(options.device, options.unpair);
      case 'disconnectOnInactivity':
        this.inactiveTimeout = setTimeout(() => {
          if (this.inactiveTimeout) this.disconnect(options.device, options.unpair).subscribe();
        }, options.device.settings.disconnectOnInactivityTimeoutMS);

        return of(null);
      case 'read':
        return of(null);
      case 'requestMtu':
        return of(null);
      case 'write':
        return new Observable((observer: Observer<any>) => {
          setTimeout(() => {
            if (options.device.type === BluetoothDeviceType.PrinterSato) {
              let isPayloadJsonObject = true;
              try {
                JSON.parse(options.writeData);
              } catch (error) {
                isPayloadJsonObject = false;
              }
              const type = options?.messageType || (options.device.settings?.rfid ? 22 : isPayloadJsonObject ? 20 : 21);
              this.printerSatoSeq += 1;
              options.writeData = {
                seq: this.printerSatoSeq,
                type: type,
                time: Date.now(),
                content: !options.writeData ? { } : {
                  print: typeof options.writeData !== 'string' ? JSON.stringify(options.writeData) : options.writeData
                }
              };

              if (this.printerSatoTimeout) clearTimeout(this.printerSatoTimeout);
              this.printerSatoTimeout = setTimeout(() => {
                if (!this.printerSatoTimeout || !this.printerSatoWriteObserver) return;

                this.printerSatoWriteObserver.error('Timeout. No response from printer.');
              }, this.printerSatoTimeoutInMS);

              this.printerSatoWriteObserver = observer;
              this.printerSatoResponse = '';
              this.log(`PrinterSato payload without content: ${JSON.stringify(options.writeData, (key: string, val: any) => { return key !== 'content' ? val : undefined; }, 1)}`);
            }

            if (options.writeData && typeof options.writeData !== 'string') options.writeData = JSON.stringify(options.writeData);

            this.writeStringToBluetoothDevice(options.device, options.writeData, observer);
          }, this.writeDelayInMS);
        }).pipe(
          mergeMap((response: any) => {
            if (!options.device.settings?.disconnectOnInactivityTimeoutMS) return of(response);

            return this.action({ command: 'disconnectOnInactivity', device: options.device })
            .pipe(
              map(() => {
                return response;
              })
            );
          }),
          catchError((error: any) => {
            const errorMsg = typeof error === 'string' ? error : JSON.stringify(error);
            this.log('Error writing data to device: ' + errorMsg);
            options.device.isConnected = false;
            if (options.device.settings?.disconnectOnInactivityTimeoutMS) {
              this.action({ command: 'disconnectOnInactivity', device: options.device }).subscribe();
            }
            return throwError(error);
          }),
        );
    }
  }

  private actionConnect(options: any) {
    return new Observable((observer: Observer<void>) => {
      const pluginInstance = this.deviceToPluginInstanceMap[options.device.id];
      if (pluginInstance) {
        cordova.exec(
          (success: any) => {
            this.log(`Device '${options.device.id}' already connected on pluginInstance '${pluginInstance}'.`);
            options.device.isConnected = true;
            observer.next(null);
            observer.complete();
          },
          (error: any) => {
            options.device.isConnected = false;

            this.connectWithRetryRoutine(options, observer);
          },
          pluginInstance,
          'isConnected',
          []
        );
      } else {
        this.connectWithRetryRoutine(options, observer);
      }
    });
  }

  private connectWithRetryRoutine(options: any, observer: Observer<void>) {
    let hasRetried = false;
    // if connection fails, always retry at least one more time because you know...bluetooth...
    this.connect(options.device, true, options.skipSaveSettingRemotely)
    .pipe(
      catchError((error: any) => {
        this.log(error);
        if (!hasRetried) {
          hasRetried = true;
          return of(null).pipe(
            delay(500),
            mergeMap(() => {
              return this.connect(options.device, true, options.skipSaveSettingRemotely);
            })
          );
        } else {
          return throwError(error);
        }
      }),
    ).subscribe(() => {
      observer.next(null);
      observer.complete();
    }, (error: any) => {
      observer.error(error);
    });
  }

  private disconnect(device: BluetoothDevice, unpair = false): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const pluginInstance = this.deviceToPluginInstanceMap[device.id];
      this.log(`Disconnecting from '${device.id}' on pluginInstance '${pluginInstance}'...${unpair ? 'and unpairing.' : ''}`);

      if (pluginInstance) {
        cordova.exec(
          (success: any) => {
            this.log(`Disconnected from '${device.id}' on pluginInstance '${pluginInstance}': ${success}`);
            this.actualDisconnectRoutine(device, unpair, observer);
          },
          (error: any) => {
            this.log(`Failed to disconnected from '${device.id}' on pluginInstance '${pluginInstance}': ${JSON.stringify(error)}`);
            if (unpair) {
              this.log(`Removing nonetheless '${device.id}' from the list of connected devices...`);
              this.actualDisconnectRoutine(device, unpair, observer);
            } else {
              this.resetPluginInstance(device);
              observer.error(error);
            }
          },
          pluginInstance,
          'disconnect',
          []
        );
      } else {
        this.log(`Couldn't find pluginInstance for device which means it is not connected.`);
        this.actualDisconnectRoutine(device, unpair, observer);
      }
    });
  }

  private actualDisconnectRoutine(device: BluetoothDevice, unpair: boolean, observer: Observer<void>) {
    this.resetPluginInstance(device);
    device.isConnected = false;

    if (unpair) {
      device.shouldBeConnected = false;
      this.localSettingsService.removeBtDevice(device);
      device.setSettingRemotely(this.setSettingsService, false);

      this.connectedDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic);
    }

    observer.next(null);
    observer.complete();
  }

  writeStringToBluetoothDevice(device: BluetoothDevice, str: string, observer: Observer<any>): Subscription {
    const writeChunkSize = device.settings.writeChunkSize != null ? device.settings.writeChunkSize : this.settings.writeChunkSize;
    if (!writeChunkSize || str.length <= writeChunkSize) {
      return this.writeChunkToBluetoothDevice(
        device,
        str,
      ).subscribe((response: any) => {
        if (device.type === BluetoothDeviceType.PrinterSato) return;

        const responseLogMsg = typeof response === 'string' ? response : JSON.stringify(response);
        this.log('Write data to device successfuly. Response: ' + responseLogMsg);
        observer.next(response);
        observer.complete();
      });
    } else {
      // Need to partion the string and write one chunk at a time.
      const chunks: string[] = [];
      let subStr = '';
      for (let i = 0; i < str.length; i += writeChunkSize) {
        if (i + writeChunkSize <= str.length) {
          subStr = str.substring(i, i + writeChunkSize)
        } else {
          subStr = str.substring(i, str.length)
        }
        chunks.push(subStr);
      }

      return from(chunks)
      .pipe(
        concatMap((chunkStr: string) => {
          return this.writeChunkToBluetoothDevice(
            device,
            chunkStr,
          ).pipe(
            delay(100)
          );
        })
      ).subscribe(() => {

      }, (error: any) => {
        this.log('Error writing chunk to BT device: ' + error);
        observer.error(error);
      }, () => {
        if (device.type === BluetoothDeviceType.PrinterSato) return;

        this.log('Write data to device successfuly. Response: OK');
        observer.next('OK');
        observer.complete();
      });
    }
  }

  private writeChunkToBluetoothDevice(device: BluetoothDevice, str: string): Observable<any> {
    // Convert str to ArrayBuff and write to device
    const buffer = new ArrayBuffer(str.length)
    const dataView = new DataView(buffer)
    for (let i = 0; i < str.length; i++) {
      dataView.setUint8(i, str.charAt(i).charCodeAt(0))
    }

    // Alternative encoding...
    // const array = new Uint8Array(str.length);
    // for (let i = 0; i < str.length; i++) {
    //   array[i] = str.charCodeAt(i);
    // }
    // const buffer = array.buffer;

    return new Observable<any>((observer: Observer<any>) => {
      cordova.exec(
        (response: any) => {
          const responseLogMsg = typeof response === 'string' ? response : JSON.stringify(response);
          this.log('Write chunk to device successfuly. Response: ' + responseLogMsg);
          observer.next(response);
          observer.complete();
        },
        (error: any) => {
          observer.error(error);
        },
        this.deviceToPluginInstanceMap[device.id],
        'write',
        [buffer]
      );
    });
  }

  start(callback?: (data: any) => void, options?: any): void {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return;
    }

    Object.assign(this.settings, options || {});

    if (options?.device) {
      this.startBtSerialSubscription(callback, options.device);
      // this.checkIfEnabledAndStartBtSerialSubscription(callback, options.device);
      return;
    }

    const scannerDevices = this.localSettingsService.getBtDevices(BluetoothDeviceMode.Classic, [BluetoothDeviceType.Scanner]);

    for (const device of scannerDevices || []) {
      if (this.deviceToStartSubscriptionMap[device.id]) continue;

      this.startBtSerialSubscription(callback, device);
      // this.checkIfEnabledAndStartBtSerialSubscription(callback, device);
    }
  }

  private checkIfEnabledAndStartBtSerialSubscription(callback: (data: any) => void, device: BluetoothDevice) {
    cordova.exec(
      () => {
        this.startBtSerialSubscription(callback, device);
      },
      (error: any) => {
        this.log('Tried to start() but bluetooth is disabled.');
      },
      this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
      'isEnabled',
      []
    );
  }

  private startBtSerialSubscription(callback: (data: any) => void, device: BluetoothDevice) {
    const pluginInstance = this.deviceToPluginInstanceMap[device.id] || 'BluetoothSerial1';
    this.log(`Starting read subscription for device '${device.id}' on pluginInstance '${pluginInstance}'`);
    if (this.settings.readDelimiter) {
      this.deviceToStartSubscriptionMap[device.id] = 'unsubscribe';
      cordova.exec(
        (data: any) => {
          device.isConnected = true;

          if (callback) callback(data);
          else this.handleScanData(data);
        },
        (error: any) => {
          this.log(`Error while reading data from device '${device.id}': ${JSON.stringify(error)}`);
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        pluginInstance,
        'subscribe',
        [this.settings.readDelimiter]
      );
    } else {
      this.deviceToStartSubscriptionMap[device.id] = 'unsubscribeRaw';
      cordova.exec(
        (data: any) => {
          device.isConnected = true;

          let dataString = String.fromCharCode.apply(null, new Uint8Array(data));
          dataString = dataString.replace(/(?:\r\n|\r|\n|\t)/g, '');
          if (callback) callback(dataString);
          else this.handleScanData(dataString);
        },
        (error: any) => {
          this.log(`Error while reading data from device '${device.id}': ${JSON.stringify(error)}`);
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        pluginInstance,
        'subscribeRaw',
        []
      );
    }
  }

  private handlePrinterSatoResponses(device: BluetoothDevice, data: string) {
    this.printerSatoResponse += (data || '').replace(//g, '');

    let validJsonResponse;
    try {
      validJsonResponse = JSON.parse(this.printerSatoResponse);
    } catch (error) {
      this.log('Partial response: ' + this.printerSatoResponse);
      return;
    }

    if (validJsonResponse.reply != this.printerSatoSeq) {
      this.log('Got response from printer but reply != seq. Response: ' + this.printerSatoResponse);
      this.printerSatoResponse = '';
      return;
    }

    this.log('Write data to device successfuly. Response: ' + this.printerSatoResponse);

    if (this.printerSatoTimeout) {
      clearTimeout(this.printerSatoTimeout);
      this.printerSatoTimeout = undefined;
    }

    if (validJsonResponse.type === 2) {
      device.settings.rfid = validJsonResponse.content?.os?.rfid;
      device.settings.dpi = validJsonResponse.content?.hd?.dpi;
    }

    this.printerSatoResponse = '';
    if (!this.printerSatoWriteObserver) return;

    if (validJsonResponse.content?.success === false) {
      this.printerSatoWriteObserver.error(`${validJsonResponse.content.ecode ? validJsonResponse.content.ecode + ' - ' : ''}${validJsonResponse.content.etext}`);
    } else {
      this.printerSatoWriteObserver.next(validJsonResponse);
      this.printerSatoWriteObserver.complete();
    }
    this.printerSatoWriteObserver = undefined;
  }

  private handleScanData(data: string) {
    this.scannerService.emitScan({
      source: 'BT SCANNER',
      value: data,
      valueType: '',
    });
  }

  stop(options?: any): void {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return;
    }

    for (const deviceId of Object.keys(this.deviceToStartSubscriptionMap)) {
      const device = this.localSettingsService.getBtDevice(deviceId);
      if (!device) continue;
      if (device.type === BluetoothDeviceType.Scanner) continue; // keep the subscription open as we want to allow the user to "keep scanning". ofc he'll get an error if the current control doesn't accept scanning

      this.log(`Stopping read subscription for device '${device.id}' on pluginInstance '${this.deviceToPluginInstanceMap[device.id]}'`);
      cordova.exec(
        (data: any) => {
          device.isConnected = false;
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        (error: any) => {
          this.log(`Error while unsubscribing from device '${device.id}': ${JSON.stringify(error)}`);
          device.isConnected = false;
          delete this.deviceToStartSubscriptionMap[device.id];
        },
        this.deviceToPluginInstanceMap[device.id] || 'BluetoothSerial1',
        this.deviceToStartSubscriptionMap[device.id],
        []
      );
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of('Cordova not available...');
    }

    return new Observable((observer: Observer<any>) => {
      cordova.exec(
        () => {
          observer.next({
            enabled: true,
            connections: this.deviceToPluginInstanceMap,
            log: Array.from(this.logRingBuffer),
          });
          observer.complete();
        },
        (error: any) => {
          observer.next({
            enabled: false,
            connections: this.deviceToPluginInstanceMap,
            log: Array.from(this.logRingBuffer),
          });
          observer.complete();
        },
        this.defaultPluginInstance, // Always use first plugin instance to perform non device / connection specific calls
        'isEnabled',
        []
      );
    });
  }

  private getKnownDevice(device: BluetoothDevice) {
    return this.connectedDevices.find((d: BluetoothDevice) => {
      return d.mode === device.mode && d.id === device.id;
    });
  }

}
