import { Injectable, NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';


@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return timer(150).pipe(flatMap(_ => load()));
  }
}

const routes: Routes = [
  {
    path: 'bluetooth-connect',
    loadChildren: () => import('./pages/bluetooth-connect/bluetooth-connect.page.module').then(m => m.BluetoothConnectPageModule),
  },
  {
    path: 'enroll-list',
    loadChildren: () => import('./pages/enroll-list/enroll-list.page.module').then(m => m.EnrollListPageModule),
  },
  {
    path: 'enroll-new',
    loadChildren: () => import('./pages/enroll-new/enroll-new.page.module').then(m => m.EnrollNewPageModule),
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.page.module').then(m => m.MainPageModule),
  },

  { path: '**', redirectTo: 'main' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { preloadingStrategy: AppPreloadingStrategy, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
