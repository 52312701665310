import { NgModule } from '@angular/core';
import { NgProgressModule } from '@ngx-progressbar/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LoadPopover } from './load.popover';



@NgModule({
  imports: [
    SharedModule,
    NgProgressModule.withConfig({
      min: 5,
      meteor: false,
      trickleSpeed: 500,
    }),
  ],
  declarations: [
    LoadPopover,
  ],
  entryComponents: [
    LoadPopover,
  ]
})
export class LoadPopoverModule { }
