import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { CameraOptions } from '@ionic-native/camera/ngx';
import { Platform } from '@ionic/angular';
import fixOrientation from 'fix-orientation';
import { from } from 'rxjs';
import { LayoutResourceUploadService } from 'src/app/shared/services/protobuf/layout-resource-upload.service';
import { DictString, Notification, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { BrowserUtils, LogUtils } from '../../../utils';
import { ControlBaseComponent } from '../base/control-base.component';


enum CameraDeviceControlCaptureImageSize {
  Default = 0,
  MegaPixel1 = 1,
  MegaPixel2 = 2,
  MegaPixel3 = 3,
  MegaPixel4 = 4,
  MegaPixel5 = 5,
  MegaPixel6 = 6,
  MegaPixel7 = 7,
  MegaPixel8 = 8,
}

@Component({
  selector: 'lc-control-camera1',
  templateUrl: 'control-camera1.component.html',
  styleUrls: ['./control-camera1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlCamera1Component extends ControlBaseComponent implements OnInit {

  b64Image: string;
  pictureGuidId: string;
  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private layoutResourceUploadService: LayoutResourceUploadService,
    private platform: Platform,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    if (this.staticControl) {
      this.b64Image = this.staticControl.b64Image;
      const autoOpen = this.staticControl.autoOpen;
      if (autoOpen) {
        setTimeout(() => {
          this.takePicture();
        }, 1);
      }
      return;
    }

    if (!this.layoutControl) return;

    const autoOpen = this.layoutControl.parseRV('AutoOpen');
    if (!autoOpen) return;

    setTimeout(() => {
      this.takePicture();
    }, 1);
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    const useControlContextValueBehaviour = this.staticControl?.useControlContextValueBehaviour || this.layoutControl?.parseRV('UseControlContextValueBehaviour');
    const controlContextValueName = useControlContextValueBehaviour ? this.staticControl?.controlContextValueName || this.layoutControl?.parseRV('ControlContextValueName') : undefined;
    if (!!this.b64Image && (!useControlContextValueBehaviour || !!controlContextValueName)) {
      const b64 = this.b64Image.split(';base64,')[1];
      const mimeType = (this.b64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];

      const preUploadMethod = this.staticControl?.preUploadMethod || this.layoutControl?.parseRV('PreUploadMethod');
      if (preUploadMethod && this.pictureGuidId) {
        context[(controlContextValueName || 'Picture') + 'GuidId'] = new RuntimeLayoutValue({
          valueJson: JSON.stringify(this.pictureGuidId),
          valueTypeId: RuntimeLayoutValueType.String
        });
      } else {
        context[controlContextValueName || 'Picture'] = new RuntimeLayoutValue({
          valueJson: JSON.stringify(b64),
          valueTypeId: RuntimeLayoutValueType.String
        });
      }
      context[(controlContextValueName || 'Picture') + 'Encoding'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(mimeType),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    context['PictureTaken'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(!!this.b64Image),
      valueTypeId: RuntimeLayoutValueType.Bool
    });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  forwardButtonOverride(): boolean {
    if (!this.b64Image) return false;

    const useControlContextValueBehaviour = this.staticControl?.useControlContextValueBehaviour || this.layoutControl?.parseRV('UseControlContextValueBehaviour');
    const controlContextValueName = useControlContextValueBehaviour ? this.staticControl?.controlContextValueName || this.layoutControl?.parseRV('ControlContextValueName') : undefined;
    const preUploadMethod = this.staticControl?.preUploadMethod || this.layoutControl?.parseRV('PreUploadMethod');
    if (preUploadMethod && (!useControlContextValueBehaviour || !!controlContextValueName)) {
      if (this.pictureGuidId) return false;

      const b64 = this.b64Image.split(';base64,')[1];
      const mimeType = (this.b64Image.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || [''])[0];
      this.layoutResourceUploadService.upload(
        mimeType,
        this.convertB64ToBinary(b64),
      ).subscribe((resourceGuidId: string) => {
        this.pictureGuidId = resourceGuidId;

        this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
      });
      return true;
    }

    return false;
  }

  takePicture() {
    this.vibrationService.vibrate();

    const captureImageSize = this.staticControl?.captureImageSize || this.layoutControl?.parseRV('CaptureImageSize');
    let targetWidth;
    let targetheight;
    // https://www.bhphotovideo.com/FrameWork/charts/resolutionChartPopup.html
    switch (captureImageSize) {
      case CameraDeviceControlCaptureImageSize.MegaPixel8:
        targetWidth = 3264;
        targetheight = 2448;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel7:
        targetWidth = 3072;
        targetheight = 2304;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel6:
        targetWidth = 3032;
        targetheight = 2008;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel5:
        targetWidth = 2560;
        targetheight = 1920;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel4:
        targetWidth = 2640;
        targetheight = 1680;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel3:
        targetWidth = 2048;
        targetheight = 1536;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel2:
        targetWidth = 1600;
        targetheight = 1200;
        break;
      case CameraDeviceControlCaptureImageSize.MegaPixel1:
      default:
        targetWidth = 1280;
        targetheight = 960;
        break;
    }

    const options: CameraOptions = {
      quality: 80,
      cameraDirection: this.camera.Direction.BACK,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE, // only used if sourceType = PHOTOLIBRARY
      sourceType: this.camera.PictureSourceType.CAMERA,
      targetWidth: targetWidth,
      targetHeight: targetheight,
      saveToPhotoAlbum: false,
      allowEdit: false,
      correctOrientation: true,
    };

    if (this.platform.is('cordova')) {
      from(this.camera.getPicture(options))
      .subscribe((b64: string) => {
        this.takePictureSuccessHandler(b64);
      }, (error: any) => {
        if (error === 'No Image Selected') {
          LogUtils.log(error);
        } else {
          LogUtils.error(error)
          this.notificationService.showNotification(new Notification({
            title: this.translateService.instant('Notification'),
            text: this.translateService.instant('Failed to take picture') + ': ' + error,
            type: RuntimeLayoutNotifyType.CriticalAlert,
            blocking: true
          }));
        }
      });
    } else {
      BrowserUtils.getPicture(
        this.takePictureSuccessHandler.bind(this),
        () => {},
        options
      );
    }

    this.cdr.markForCheck();
  }

  private takePictureSuccessHandler(b64: string) {
    this.b64Image = 'data:image/jpeg;base64,' + b64;

    // on some phone models, pictures get rotated for some obscure reason,
    // with the correct orientatino being saved in EXIF...so lets fix that!
    fixOrientation(this.b64Image, { image: true }, (fixed) => {
      this.b64Image = fixed;
      this.cdr.markForCheck();

      const skipReview = this.staticControl?.skipReview || this.layoutControl?.parseRV('SkipReview');
      if (skipReview) {
        this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
        this.vibrationService.vibrate();
      }
    });
  }

  deletePicture() {
    this.b64Image = undefined;

    this.cdr.markForCheck();
  }

  private convertB64ToBinary(b64: string): Uint8Array {
    var raw = window.atob(b64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return new Uint8Array(array);
  }

}

