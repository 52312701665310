// ', @, #
export const KeyboardDefinition: any = {
  Alpha: {
    English: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '\''],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '@', '#'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', ''],
        ['!', '@', ',', { label: 'SPACE', value: ' ' }, '.', '', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '\''],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '@', '#'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['!', '@', ',', { label: 'SPACE', value: ' ' }, '.', '', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    },
    Swedish: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', ''],
        ['!', '@', ',', { label: 'SPACE', value: ' ' }, '.', '', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Å'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ö', 'Ä'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['!', '@', ',', { label: 'SPACE', value: ' ' }, '.', '', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    }
  },
  Numeric: {
    English: {
      keySet1: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['.', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
    },
    Swedish: {
      keySet1: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['.', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
    }
  },
  AlphaNumeric: {
    English: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '\''],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '@', '#'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '\''],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '@', '#'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet3: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['abc', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet4: [
        ['@', '#', '$', '_', '&', '-', '+', '(', ')'],
        ['|', 'º', '^', '~', '=', '!', '?', '{', '}'],
        ['\\', '/', '*', '"', '\'', ':', ';', '[', ']'],
        ['abc', '%', '<', { label: 'SPACE', value: ' ' }, '>', '.', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    },
    Swedish: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Å'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ö', 'Ä'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet3: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['abc', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet4: [
        ['@', '#', '$', '_', '&', '-', '+', '(', ')'],
        ['|', 'º', '^', '~', '=', '!', '?', '{', '}'],
        ['\\', '/', '*', '"', '\'', ':', ';', '[', ']'],
        ['abc', '%', '<', { label: 'SPACE', value: ' ' }, '>', '.', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    }
  },
  AlphaNumericWithEnter: {
    English: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '\''],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '@', '#'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', { icon: 'fat fa-arrow-turn-down-left', value: 'Enter' }],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', '\''],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '@', '#'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet3: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['abc', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet4: [
        ['@', '#', '$', '_', '&', '-', '+', '(', ')'],
        ['|', 'º', '^', '~', '=', '!', '?', '{', '}'],
        ['\\', '/', '*', '"', '\'', ':', ';', '[', ']'],
        ['abc', '%', '<', { label: 'SPACE', value: ' ' }, '>', '.', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    },
    Swedish: {
      keySet1: [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ö', 'ä'],
        [{ icon: 'fat fa-arrow-up', value: 'shift' }, 'z', 'x', 'c', 'v', 'b', 'n', 'm', { icon: 'fat fa-arrow-turn-down-left', value: 'Enter' }],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet2: [
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Å'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Ö', 'Ä'],
        [{ icon: 'fat fa-arrow-down', value: 'shift' }, 'Z', 'X', 'C', 'V', 'B', 'N', 'M', ''],
        ['123', '@', ',', { label: 'SPACE', value: ' ' }, '.', '!&', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet3: [
        ['1', '2', '3'],
        ['4', '5', '6'],
        ['7', '8', '9'],
        ['abc', '0', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ],
      keySet4: [
        ['@', '#', '$', '_', '&', '-', '+', '(', ')'],
        ['|', 'º', '^', '~', '=', '!', '?', '{', '}'],
        ['\\', '/', '*', '"', '\'', ':', ';', '[', ']'],
        ['abc', '%', '<', { label: 'SPACE', value: ' ' }, '>', '.', { icon: 'fat fa-delete-left', value: 'Backspace' }],
      ]
    }
  }
};
