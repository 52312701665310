import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfirmPopover } from './confirm.popover';



@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ConfirmPopover,
  ],
  entryComponents: [
    ConfirmPopover,
  ]
})
export class ConfirmPopoverModule { }
