import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackgroundGeolocation } from '@awesome-cordova-plugins/background-geolocation/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BLE } from '@ionic-native/ble/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { IonicModule } from '@ionic/angular';
import { ShadowCssDirective } from './directives/shadow-css.directive';
import { SharedPipesModule } from './pipes/shared-pipes.module';


@NgModule({
  declarations: [
    ShadowCssDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedPipesModule,
    ShadowCssDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedPipesModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [
            AndroidFullScreen,
            Autostart,
            BackgroundGeolocation,
            BarcodeScanner,
            BLE,
            BluetoothSerial,
            Camera,
            Clipboard,
            Device,
            File,
            InAppBrowser,
            Keyboard,
            NativeAudio,
            NFC,
            Ndef,
            ScreenOrientation,
            Vibration,
        ]
    };
}
}
