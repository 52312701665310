import { Injectable, Injector } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { BrowserUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';


export interface HoneywellPluginSettings {
}


@Injectable({
  providedIn: 'root'
})
export class HoneywellPlugin extends BasePlugin {

  private readonly intentClaimScanner = 'com.honeywell.aidc.action.ACTION_CLAIM_SCANNER';
  private readonly intentReleaseScanner = 'com.honeywell.aidc.action.ACTION_RELEASE_SCANNER';
  private readonly intentControlScanner = 'com.honeywell.aidc.action.ACTION_CONTROL_SCANNER';
  private readonly intentAction = 'se.mobilelogic.logiccenter.mobile.ACTION';

  private isPluginAllowedChecked: boolean;
  private settings: HoneywellPluginSettings;

  constructor(
    private device: Device,
    injector: Injector,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'HoneywellPlugin';

    this.settings = {};
  }

  isPluginAllowed(): boolean {
    const manufacturer = this.platform.is('cordova') ? (this.device.manufacturer || '').toLowerCase() : '';
    return BrowserUtils.isDeviceApp() && manufacturer.indexOf('honeywell') >= 0;
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Honeywell device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    this.registerScannerCallback();

    return of(null);
  }

  private registerScannerCallback(): void {
    this.log('Registering to receive scanner data...');
    (window as any).plugins.intentShim.unregisterBroadcastReceiver();
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        this.intentAction,
      ],
      filterCategories: [
        'android.intent.category.DEFAULT',
      ]},
      this.handleScannerIntent.bind(this)
    );
  }

  private handleScannerIntent(intent: any): void {
    intent.extras = intent.extras || {};
    // LogUtils.log('Honeywell Intent: ', intent);
    if (intent.action !== this.intentAction) return;

    const value = intent.extras['data'];
    const valueType = intent.extras['aimId'];
    this.scannerService.emitScan({
      source: 'SCANNER',
      value: value,
      valueType: valueType,
    });
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Honeywell device...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    switch(options.command) {
      case 'enable_scanner':
        this.log('Enabling scanner...');
        this.sendBroadcast(
          this.intentClaimScanner,
          {
            'com.honeywell.aidc.extra.EXTRA_PROFILE': 'DEFAULT',
            'com.honeywell.aidc.extra.EXTRA_PROPERTIES': {
              'DPR_DATA_INTENT': true,
              'DPR_DATA_INTENT_ACTION': this.intentAction,
            }
          },
        );
        return of(null);
      case 'disable_scanner':
        this.log('Disabling scanner...');
        this.sendBroadcast(
          this.intentReleaseScanner,
          {},
        );
        return of(null);
      case 'has_scanner':
        // this.log('Has internal scanner / imager? ' + this.hasScanner);
        return of(true);
      case 'toggle_soft_scan':
        this.log('Toggle soft scanner state...');
        this.sendBroadcast(
          this.intentControlScanner,
          {
            'com.honeywell.aidc.extra.EXTRA_SCAN': true,
          },
        );
        return of(null);
      default:
        return of(null);
    }
  }

  start(callback?: (data: any) => void, options?: any): void {
    Object.assign(this.settings, options || {});

    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Honeywell device...');
      this.isPluginAllowedChecked = true;
      return;
    }
  }

  stop(options?: any): void {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Honeywell device...');
      this.isPluginAllowedChecked = true;
      return;
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Not running on Honeywell device...');
      this.isPluginAllowedChecked = true;
      return of('Not running on Honeywell device...');
    }

    return of({
      enabled: this.isPluginAllowed(),
      log: Array.from(this.logRingBuffer),
    });
  }

  private sendBroadcast(actionName: string, extras: any) {
    (window as any).plugins.intentShim.sendBroadcast({
      action: actionName,
      extras: extras
    },
      () => { }, // Success in sending the intent, not success of DW to process the intent.
      () => { }, // Failure in sending the intent, not failure of DW to process the intent.
    );
  }

}
