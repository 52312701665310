import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValueType } from 'src/app/shared/models';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { DictString, RuntimeLayoutValue } from '../../../models';
import { KeyboardService } from '../../keyboard/services/keyboard.service';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlInput1Component } from '../input1/control-input1.component';


@Component({
  selector: 'lc-control-dimension1',
  templateUrl: 'control-dimension1.component.html',
  styleUrls: ['./control-dimension1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlDimension1Component extends ControlBaseComponent implements OnInit, OnDestroy {

  readonly thisDocument: Document = document;

  @ViewChild('controlWidthComponent', { static: false }) controlWidthComponent: ControlInput1Component;
  @ViewChild('controlHeightComponent', { static: false }) controlHeightComponent: ControlInput1Component;
  @ViewChild('controlLengthComponent', { static: false }) controlLengthComponent: ControlInput1Component;

  theme: string;

  currentScaleSend: any;
  isManual: boolean;
  staticControlWidth: any;
  staticControlHeight: any;
  staticControlLength: any;
  unitOfMeasure: string;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private keyboardService: KeyboardService,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.layoutScreen.forwardButton = true;

    this.unitOfMeasure = this.layoutControl.parseRV('UoM');

    this.refresh();
  }

  refresh() {
    this.isManual = true;

    this.staticControlWidth = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Width') + (this.unitOfMeasure ? ' (' + this.unitOfMeasure + ')' : '') + ':',
      scannerEnabledType: this.layoutControl.scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };

    this.staticControlHeight = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Height') + (this.unitOfMeasure ? ' (' + this.unitOfMeasure + ')' : '')  + ':',
      scannerEnabledType: this.layoutControl.scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };

    this.staticControlLength = {
      controlHeadlineEnabled: true,
      controlHeadlineText: this.translateService.instant('Depth') + (this.unitOfMeasure ? '(' + this.unitOfMeasure + ')' : '')  + ':',
      scannerEnabledType: this.layoutControl.scannerEnabledType,
      keyboardType: KeyboardType.Numeric,
      defaultTextBoxValue: undefined
    };
    this.cdr.markForCheck();
  }

  forwardButtonOverride(): boolean {
    if (this.controlWidthComponent?.inputRef?.nativeElement === this.keyboardService.getActiveElement()) {
      const nextInput = this.controlHeightComponent.inputRef.nativeElement;
      nextInput.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      nextInput.select();
      nextInput.focus();
      this.keyboardService.setActiveElement(
        nextInput,
        this.controlHeightComponent.onSoftwareKeyPress.bind(this.controlHeightComponent),
      );
      this.cdr.markForCheck();

      return true;
    } else if (this.controlHeightComponent?.inputRef?.nativeElement === this.keyboardService.getActiveElement()) {
      const nextInput = this.controlLengthComponent.inputRef.nativeElement;
      nextInput.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      nextInput.select();
      nextInput.focus();
      this.keyboardService.setActiveElement(
        nextInput,
        this.controlHeightComponent.onSoftwareKeyPress.bind(this.controlHeightComponent),
      );
      this.cdr.markForCheck();

      return true;
    }

    const width = RuntimeLayoutValue.parse((this.controlWidthComponent.getControlContext() || {}).TextBox);
    const height = RuntimeLayoutValue.parse((this.controlHeightComponent.getControlContext() || {}).TextBox);
    const length = RuntimeLayoutValue.parse((this.controlLengthComponent.getControlContext() || {}).TextBox);

    if (!width && !height && !length) {
      this.otherPortHandler('NoData');
      return true;
    } else if (width && height && length) {
      this.otherPortHandler('Dimensions');
      return true;
    }

    return false;
  }

  private otherPortHandler(portName: string) {
    const eventContextValues: any = {};

    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    });

    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    const width = RuntimeLayoutValue.parse((this.controlWidthComponent.getControlContext() || {}).TextBox);
    const height = RuntimeLayoutValue.parse((this.controlHeightComponent.getControlContext() || {}).TextBox);
    const length = RuntimeLayoutValue.parse((this.controlLengthComponent.getControlContext() || {}).TextBox);

    context['WidthInMillimeter'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(width),
      valueTypeId: RuntimeLayoutValueType.Double
    });

    context['HeightInMillimeter'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(height),
      valueTypeId: RuntimeLayoutValueType.Double
    });

    context['LenghtInMillimeter'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(length),
      valueTypeId: RuntimeLayoutValueType.Double
    });

    context['Manual'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(this.isManual),
      valueTypeId: RuntimeLayoutValueType.Bool
    });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  tryParseDimensionCipherlabScan(controlComponent: any) {
    const possibleDimensionScan = RuntimeLayoutValue.parse((controlComponent.getControlContext() || {}).TextBox);
    if (
      (
        possibleDimensionScan.indexOf('<3D>') === 0
        || possibleDimensionScan.indexOf('<2D>') === 0
      ) && possibleDimensionScan.indexOf('\r\n') > 0
    ) {
      // we got a dimension!
      const uom = possibleDimensionScan.substring(23, 27).trim().toLowerCase();
      const uomMultiplier = uom === 'cm' ? 10 : uom === 'inch' ? 25.4 : 1;
      const width = parseFloat(possibleDimensionScan.substring(26, 31).trim() || '0.0');
      const height = parseFloat(possibleDimensionScan.substring(32, 37).trim() || '0.0');
      const length = parseFloat(possibleDimensionScan.substring(38, 43).trim() || '0.0');
      this.controlWidthComponent.value = '' + (width * uomMultiplier);
      this.controlHeightComponent.value = '' + (height * uomMultiplier);
      this.controlLengthComponent.value = '' + (length * uomMultiplier);
      this.isManual = false;
    } else {
      controlComponent.value = possibleDimensionScan;
      this.isManual = true;
    }
    this.cdr.markForCheck();
  }

}

