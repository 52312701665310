import { SetSettingsService } from '../services';
import { DateUtils, LogUtils } from '../utils';
import { EnumUtils } from '../utils/enum.utils';
import { RuntimeLayoutSetting, RuntimeLayoutSettingGroup } from './runtime-layout/setting';
import { RuntimeLayoutSettingGroupSetType } from "./runtime-layout/setting/runtime-layout-setting-group-set-type.enum";
import { RuntimeLayoutSettingType } from "./runtime-layout/setting/runtime-layout-setting-type.enum";

export enum BluetoothDeviceMode {
  Classic = 'classic',
  BLE = 'ble',
}

export enum BluetoothDeviceType {
  ESL = 'esl',
  Printer = 'printer', // originally we only printed to Zebra printers, but if a non-zebra printer uses BTClassic, this may also work...
  PrinterSato = 'printer-sato',
  Scanner = 'scanner',
  Thermometer = 'thermometer',
  Unknown = '',
}

export class BluetoothDeviceCharacteristic {
  service: string;
  characteristic: string;
  descriptors: { uuid: string }[];
  properties: string[];
}

export class BluetoothDevice {

  static readonly EslConfigServiceUUID = 'FEA0';
  static readonly EslConfigWriteCharacteristicUUID = 'FEA1';
  static readonly EslConfigNotifyCharacteristicUUID = 'FEA2';
  static readonly HealthThermometerServiceUUID = '1809';
  static readonly TemperatureMeasurementCharacteristicUUID = '2A1C';
  static readonly TemperatureStreamCharacteristicUUID = '2A1E';
  static readonly ZebraPrinterServiceUUID = '38eb4a80-c570-11e3-9507-0002a5d5c51b';
  static readonly ZebraPrinterCharacteristicUUID = '38eb4a82-c570-11e3-9507-0002a5d5c51b';
  static readonly PrinterClass = 1664;
  static readonly UncategorizedClass = 7936;

  static readonly TimeoutAfterConnect = 250;

  private readonly settingGroupPath: string = 'Plugin/Bluetooth/';

  name: string;
  id: string;
  mode: BluetoothDeviceMode;
  advertising: Uint8Array;
  rssi: number; // ble
  characteristics: BluetoothDeviceCharacteristic[]; // ble
  services: string[]; // ble

  class: number; // bt classic, also checked for in case of printer (1664)

  shouldBeConnected: boolean;
  isConnected: boolean;

  type: BluetoothDeviceType;
  settings: any;


  constructor(device?: Partial<BluetoothDevice>) {
    Object.assign(this, device);

    this.settings = this.settings || {};

    if (this.type as string === 'zebra_printer') {
      this.type = BluetoothDeviceType.Printer;
    }
  }

  setSettingRemotely(setSettingsService: SetSettingsService, active = true) {
    const settingGroup = new RuntimeLayoutSettingGroup({
      active: active,
      path: this.settingGroupPath,
      name: this.id,
      settingGroupDateTime: DateUtils.nowAsISOString(),
      setType: RuntimeLayoutSettingGroupSetType.Hardware,
      settings: [
        new RuntimeLayoutSetting({
          name: 'id',
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.id
        }),
        new RuntimeLayoutSetting({
          name: 'mode',
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.mode
        }),
        new RuntimeLayoutSetting({
          name: 'name',
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.name
        }),
        new RuntimeLayoutSetting({
          name: 'type',
          settingType: RuntimeLayoutSettingType.Setting,
          value: this.type
        }),
      ]
    });

    setSettingsService.trigger(settingGroup)
    .subscribe(() => {
      LogUtils.log(`BT Device '${this.id}' setting updated remotely. Active=${active}`);
    }, (error: any) => {
      console.error(error);
    });
  }

  static buildFromConnectString(btDeviceString: string): BluetoothDevice {
    try {
      const tryAtob = atob(btDeviceString);
      btDeviceString = tryAtob;
    } catch (error) {
      // do nothing...if we scanned a value - use that. if we NFCed a value, we need to ATOB it
    }
    LogUtils.log('parseConnectString: ' + btDeviceString);
    const btDeviceStringSplit = (btDeviceString || '').split('|');
    if (btDeviceString.indexOf('*unitech smart config*') >= 0) {
      return new BluetoothDevice({
        mode: BluetoothDeviceMode.Classic,
        id: btDeviceString.substring(btDeviceString.indexOf('bt_mac=') + 'bt_mac='.length, 17),
        type: BluetoothDeviceType.Scanner,
      });
    }

    if (btDeviceStringSplit.length < 3) return null;

    const possibleType = btDeviceStringSplit[2].toLowerCase() as BluetoothDeviceType;
    if (EnumUtils.toStringArray(BluetoothDeviceType).filter(x => x).indexOf(possibleType) < 0) return null;

    const device = new BluetoothDevice({
      mode: btDeviceStringSplit[0].toLowerCase() as BluetoothDeviceMode,
      id: btDeviceStringSplit[1].toUpperCase(),
      type: possibleType,
    });
    if ([BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato].indexOf(device.type) >= 0) {
      device.settings.disconnectOnInactivityTimeoutMS = btDeviceStringSplit.length === 4 ? parseInt(btDeviceStringSplit[3]) : 0;
    }
    return device;
  }

  static removeDuplicatesDevices(devices: BluetoothDevice[]) {
    return (devices || [])
      .sort((a, b) => { return (a.class + a.id).localeCompare((b.class + b.id)); })
      .filter((current, index, array) => {
        return index === 0 || current.id !== array[index - 1].id;
      })
  }

}
