
  <div class="side-menu">

		<div *ngIf="!deviceEnrollment?.deviceSolutionSetName"
			class="header">
			<h2 class="bigger-bolder">{{ 'v' + appVersion }}</h2>
		</div>

		<div *ngIf="deviceEnrollment?.deviceSolutionSetName"
			class="header">
			<div class="solution">
				{{ deviceEnrollment.deviceSolutionName }} <b>v{{ deviceEnrollment.deviceSolutionVersion }}</b>
			</div>

			<h2 class="bigger-bolder">{{ deviceId || 'v' + appVersion }}</h2>

			<!--<span class="solution-set-type">
				<b>SolutionSet:</b> {{ deviceEnrollment.deviceSolutionSetVersionType }}
			</span>

			<span class="solution-set">
				{{ deviceEnrollment.deviceSolutionSetName }} <b>v{{ deviceEnrollment.deviceSolutionSetVersion }}</b>
			</span>

			<span class="solution-type">
				<b>Solution:</b> {{ deviceEnrollment.deviceSolutionVersionType }}
			</span>
			<span class="solution">
				{{ deviceEnrollment.deviceSolutionName }} <b>v{{ deviceEnrollment.deviceSolutionVersion }}</b>
			</span>-->
		</div>

		<nav>
			<ul>
				<li *ngIf="isDeviceApp || isRunDevice || isLocalhost">
					<ion-menu-toggle autoHide="false">
						<a class="globe"
							href="javascript:void(0);"
							(click)="goToEnrollment()">
							{{ 'Enrollment' | translate }}
						</a>
					</ion-menu-toggle>
				</li>

				<!--<li *ngIf="isDeviceApp">
					<ion-menu-toggle autoHide="false">
						<a class="print"
							href="javascript:void(0);"
							(click)="openModal(modalPrinter)">
							{{ 'Printers' | translate }}
						</a>
					</ion-menu-toggle>
				</li>-->

				<li *ngIf="showBluetoothMenu"
					[style.opacity]="!!deviceEnrollment ? '1' : '.4'">
					<ion-menu-toggle autoHide="false">
						<a class="bluetooth"
							href="javascript:void(0);"
							(click)="goToBluetooth()">
							{{ 'Bluetooth' | translate }}
						</a>
					</ion-menu-toggle>
				</li>

				<!--<li style="opacity: .4;">
					<a class="wifi" href="javascript:void(0);">
						{{ 'Wifi' | translate }}
					</a>
				</li>

				<li style="opacity: .4;">
					<a class="watch" href="javascript:void(0);">
						{{ 'Smart Watch' | translate }}
					</a>
				</li>

				<li style="opacity: .4;">
					<a class="glasses" href="javascript:void(0);">
							{{ 'Glasses' | translate }}
					</a>
				</li>-->

				<li>
					<ion-menu-toggle autoHide="false">
						<a class="settings"
							href="javascript:void(0);"
							(click)="openModal(modalSettings)">
							{{ 'Settings' | translate }}
						</a>
					</ion-menu-toggle>
				</li>

				<li>
					<ion-menu-toggle autoHide="false">
						<a class="info"
							href="javascript:void(0);"
							(click)="openModal(modalSystemInfo)">
							{{ 'System Info' | translate }}
						</a>
					</ion-menu-toggle>
				</li>

				<li>
					<ion-menu-toggle autoHide="false">
						<a class="debug"
							href="javascript:void(0);"
							(click)="openModal(modalDebug)">
							{{ 'Debug' | translate }}
						</a>
					</ion-menu-toggle>
				</li>

				<li *ngIf="!deviceEnrollment?.$deviceRunStatus"
					[style.opacity]="!!deviceEnrollment ? '1' : '.4'">
					<ion-menu-toggle autoHide="false">
						<a class="restart"
							href="javascript:void(0);"
							(click)="!!deviceEnrollment ? deviceRestart(false) : enrollFirst()">
							<!--(press)="deviceRestart(true)">-->
							{{ 'Restart' | translate }}
						</a>
					</ion-menu-toggle>
        </li>
			</ul>
		</nav>

		<div class="flex-1"></div>

		<div class="footer">
			<div class="flex-row middle">
				<div class="flex-1"></div>

				<div class="name">
					LogicCenter
				</div>
			</div>

			<div class="flex-row middle">
				<div class="flex-1"></div>

				<div class="version">
					v{{ appVersion }}
				</div>
			</div>

			<div class="flex-row middle center">
				<div class="flex-1"></div>

				<div class="by">
					by
				</div>

				<img class="image"
					alt="logo"
					src="../assets/img/logo.jpg" />
			</div>
		</div>
	</div>

