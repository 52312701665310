import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BluetoothMenuPopover } from './bluetooth-menu.popover';



@NgModule({
  declarations: [
    BluetoothMenuPopover,
  ],
  entryComponents: [
    BluetoothMenuPopover,
  ],
  imports: [
    SharedModule,
  ],
})
export class BluetoothMenuPopoverModule { }
