import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SelectPrinterPopover, TestPrinterPopover } from 'src/app/popovers';
import { KeyboardType } from 'src/app/shared/models/keyboard-type.enum';
import { DictString, Notification, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutValue, RuntimeLayoutValueType, SolutionDeviceControlScannerEnabledFlagType } from '../../../models';
import { BluetoothDevice, BluetoothDeviceType } from '../../../models/bluetooth-device.model';
import { BasePlugin, PluginType } from '../../../services';
import { BrowserUtils } from '../../../utils/browser-utils';
import { ControlBaseComponent } from '../base/control-base.component';
import { ControlInput1Component } from '../input1/control-input1.component';
import { LogUtils } from 'src/app/shared/utils';


@Component({
  selector: 'lc-control-print1',
  templateUrl: 'control-print1.component.html',
  styleUrls: ['./control-print1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPrint1Component extends ControlBaseComponent implements OnInit, OnDestroy {

  static readonly emptySatoPayload = JSON.stringify({
    "type":"label",
    "name":"Queue",
    "design_height":960,
    "design_width":960,
    "fields":[{
     "fieldtype":"text",
     "name":"Empty",
     "hPos":128,
     "vPos":208,
     "size":16,
     "value":"EMPTY PAYLOAD"
    }]
  });
  static readonly emptyZebraPayload = '^XA^FO200,50^FDEMPTY PAYLOAD...^FS^XZ';
  static readonly testSatoPayload = JSON.stringify({
    "type":"label",
    "name":"Queue",
    "design_height":960,
    "design_width":960,
    "fields":[{
      "fieldtype":"text",
      "name":"QueueNr",
      "hPos":144,
      "vPos":208,
      "size":40,
      "value":"1"
    }, {
      "fieldtype":"line",
      "name":"Line",
      "hPos":90,
      "vPos":452,
      "hDelta":786,
      "vDelta":0,
      "thickness":1
    }, {
      "fieldtype":"text",
      "name":"Department",
      "hPos":128,
      "vPos":525,
      "size":16,
      "value":"sales"
    }, {
      "fieldtype":"text",
      "name":"Greeting",
      "hPos":128,
      "vPos":669,
      "size":16,
      "value":"Welcome!"
    }]
  });
  static readonly testSatoRfidPayload = JSON.stringify({
    "design_dpmm":12,
    "design_height":400,
    "design_width":400,
    "fields":[{
      "_sdkGroupID":"",
      "anchor":"TOP_LEFT",
      "dir":0,
      "embolden":false,
      "face":1,
      "fieldtype":"text",
      "font":"SatoSans.ttf",
      "fonttype":"truetype",
      "format":"%s",
      "formatter":"default",
      "hPos":98,
      "id":"CRpoFsK",
      "lock":true,
      "name":"Text1",
      "oblique":false,
      "pen":"NORMAL",
      "postfix":"",
      "postscript":"",
      "prefix":"",
      "prescript":"",
      "shear":0,
      "size":12,
      "source":"fix",
      "update":false,
      "vPos":165,
      "value":"RFID Dummy",
      "visible":true
    }],
    "name":"DummyLabel",
    "type":"label",
    "rfid": {
      "epc": "ABCD12345678ABCD"
    }
  });
  static readonly testZebraPayload = `^XA
^CI28
^FO100,50^ADN,30,10^FD239706 ServiceOrder
^FS
^FO100,110^BY3
^BCN,80,N,N,N
^FD180-2
^FS
^FO80,210^ADN,28,10^FDLeverans: Butik Västberga
^FS
^FO100,280^ADN,27,9^FDCykloteket: 123456789
^FS
^FO20,320 ^ BY3
^BCN,70,N,N,N
^FD123456789
^FS
^XZ`;


  readonly checkForPrintersTimeout = 10 * 1000;

  @ViewChild('controlSinglePrintComponent') controlSinglePrintComponent: ControlInput1Component;
  @ViewChild('controlVerificationComponent') controlVerificationComponent: ControlInput1Component;

  private btPlugin: BasePlugin;
  private failedPrintReason: string;
  private payload: string;
  private payloadType: string;
  private printCount: number;
  private printed: boolean;
  private printerDevices: BluetoothDevice[];


  printResponse: any;
  scanVerification: boolean;
  singlePrint: boolean;
  singlePrintDevice: BluetoothDevice;
  staticControlSinglePrint: any;
  staticControlVerification: any;
  theme: string;


  static defaultPrinter: BluetoothDevice;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.btPlugin = this.pluginService.getInstance(PluginType.Bluetooth);

    this.refresh();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.appService.setStatusBarMessage('');
    this.scannerService.ignoreScanInPrimaryLayoutControl = false;
  }

  refresh() {
    this.payload = this.layoutControl?.parseRV('Payload') || '';
    this.payloadType = this.layoutControl?.parseRV('PayloadType') || '';
    this.printCount = this.layoutControl?.parseRV('PrintCount') || 1;
    this.scanVerification = this.layoutControl?.parseRV('ScanVerification') || false;
    this.singlePrint = this.layoutControl?.parseRV('SinglePrint') || false;

    const simpleBarcodeScannerEnabledType = SolutionDeviceControlScannerEnabledFlagType.Simple | SolutionDeviceControlScannerEnabledFlagType.BuiltInScanner | SolutionDeviceControlScannerEnabledFlagType.BluetoothScanner;
    if (this.singlePrint) {
      this.staticControlSinglePrint = {
        controlHeadlineEnabled: true,
        controlHeadlineText: this.translateService.instant('Connect to Printer') + ':',
        scannerEnabledType: simpleBarcodeScannerEnabledType,
        keyboardType: KeyboardType.None,
      };
    }
    if (this.scanVerification) {
      this.staticControlVerification = {
        controlHeadlineEnabled: true,
        controlHeadlineText: this.translateService.instant('Scan Verification') + ':',
        scannerEnabledType: simpleBarcodeScannerEnabledType,
        keyboardType: KeyboardType.None,
        verificationErrorText: this.layoutControl.parseRV('ScanVerificationText'),
      };
    }
    this.cdr.markForCheck();

    setTimeout(() => {
      this.start();
      this.cdr.markForCheck();
    }, 100);

    if (!this.singlePrint && !this.scanVerification) return;

    this.scannerService.ignoreScanInPrimaryLayoutControl = true;
    this.cdr.markForCheck();
  }

  start() {
    if (this.singlePrint && !this.singlePrintDevice) {
      let btDeviceString: string = RuntimeLayoutValue.parse((this.controlSinglePrintComponent.getControlContext() || {}).TextBox) || '';
      if (!btDeviceString) return;

      this.singlePrintDevice = BluetoothDevice.buildFromConnectString(btDeviceString);
      if (!this.singlePrintDevice) {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('Invalid BT device string format'),
          type: RuntimeLayoutNotifyType.VerificationAlert
        }));
        return;
      }
    }

    const isTestDeveloper = this.localSettingsService.get().runDeviceDebugTestDeveloper;
    if (isTestDeveloper) {
      this.showTestPopover();
    } else {
      this.tryToPrint();
    }
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    context['Printed'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(!!this.printed),
      valueTypeId: RuntimeLayoutValueType.Bool
    });

    if (this.printResponse?.content?.tid) {
      context['RfidTID'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.printResponse?.content?.tid),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (this.printResponse?.content?.xtid) {
      context['RfidXTID'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.printResponse?.content?.xtid),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (!this.printed) {
      context['FailedPrintedReason'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.failedPrintReason || this.translateService.instant('-- No error message--')),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (!this.printed) {
      context['FailedPrintedReason'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(this.failedPrintReason || this.translateService.instant('-- No error message--')),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  tryToPrint() {
    this.failedPrintReason = undefined;
    this.appService.setStatusBarMessage('');
    this.vibrationService.vibrate();

    const satoPlugin = this.pluginService.getInstance(PluginType.Sato);
    if (satoPlugin.isPluginAllowed()) {
      try {
        let payload = this.payload != null
        ? this.parseAllergens(this.payload)
        : (this.payloadType === 'ZPL' ? ControlPrint1Component.emptyZebraPayload : ControlPrint1Component.emptySatoPayload);

        LogUtils.log(`SATO Print ${this.payloadType}`, payload);
        satoPlugin.action({
          command: 'write',
          payloadType: this.payloadType,
          printCount: this.printCount,
          writeData: payload,
        }).subscribe(() => {
          this.successPrintHandler(this.translateService.instant('Printed'));
        }, (error) => {
          this.errorPrintHandler(error);
        });
      }
      catch (error) {
        this.errorPrintHandler(error);
      }
    } else if (BrowserUtils.isDeviceApp()) {
      this.printerDevices = this.localSettingsService.getBtDevices(null, [BluetoothDeviceType.Printer, BluetoothDeviceType.PrinterSato]);

      if (this.singlePrint && this.singlePrintDevice) {
        const defaultPayload = this.singlePrintDevice.type === BluetoothDeviceType.PrinterSato
        ? ControlPrint1Component.emptySatoPayload
        : ControlPrint1Component.emptyZebraPayload;
        this.connectIfNeededAndPrint(
          this.singlePrintDevice,
          this.payload || defaultPayload,
        );
        return;
      }

      if (!this.printerDevices?.length) {
        this.notificationService.showNotification(new Notification({
          title: this.translateService.instant('Notification'),
          text: this.translateService.instant('No Bluetooth printers found / configured...'),
          type: RuntimeLayoutNotifyType.Alert,
        }));
        return;
      }

      const defaultPrinter = ControlPrint1Component.defaultPrinter
      ? this.printerDevices.find((d: BluetoothDevice) => {
          return d.mode === ControlPrint1Component.defaultPrinter.mode && d.id === ControlPrint1Component.defaultPrinter.id;
        })
      : null;
      if (defaultPrinter || this.printerDevices.length === 1) {
        const device = defaultPrinter || this.printerDevices[0];
        const defaultPayload = device.type === BluetoothDeviceType.PrinterSato
        ? ControlPrint1Component.emptySatoPayload
        : ControlPrint1Component.emptyZebraPayload;
        this.connectIfNeededAndPrint(
          device,
          this.payload || defaultPayload,
        );
      } else {
        setTimeout(() => {
          this.showPrinterSelector()
          .subscribe((device: BluetoothDevice) => {
            // set printer as default so we don't keep asking the user the same question
            ControlPrint1Component.defaultPrinter = device;
            const defaultPayload = device.type === BluetoothDeviceType.PrinterSato
            ? ControlPrint1Component.emptySatoPayload
            : ControlPrint1Component.emptyZebraPayload;

            this.connectIfNeededAndPrint(
              device,
              this.payload || defaultPayload,
            );
          });
        }, 50);
      }
    } else {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Printing is only available on device apps.'),
        type: RuntimeLayoutNotifyType.Alert
      }));
    }
  }

  private showTestPopover(): void {
    from(this.popoverCtrl.create({
      component: TestPrinterPopover,
      cssClass: `popover-test-printer`,
      backdropDismiss: false,
      showBackdrop: true
    }))
    .pipe(
      mergeMap((popover: HTMLIonPopoverElement) => {
        popover.present();
        return from(popover.onDidDismiss())
        .pipe(
          map((result: OverlayEventDetail<any>) => {
            return result.data;
          })
        );
      })
    ).subscribe((context: any) => {
      if (context) {
        this.printed = context.printed;

        if (this.printed) {
          this.successPrintHandler('Printed');
        } else {
          this.errorPrintHandler(context.failedPrintReason);
        }
      } else {
        this.tryToPrint();
      }
    });
  }

  private showPrinterSelector(): Observable<BluetoothDevice> {
    return from(this.popoverCtrl.create({
      component: SelectPrinterPopover,
      cssClass: `popover-select-printer`,
      backdropDismiss: false,
      showBackdrop: true
    }))
    .pipe(
      mergeMap((popover: HTMLIonPopoverElement) => {
        popover.present();
        return from(popover.onDidDismiss())
        .pipe(
          map((result: OverlayEventDetail<BluetoothDevice>) => {
            return result.data;
          })
        );
      })
    );
  }

  private connectIfNeededAndPrint(device: BluetoothDevice, payload: string) {
    if (device.isConnected) return this.print(device, payload);

    this.busyService.setBusy(
      true,
      this.translateService.instant('Connecting to printer...')
    );
    this.cdr.markForCheck();

    this.btPlugin.action({
      command: 'connect',
      device: device,
      skipSaveSettingRemotely: true,
    })
    .subscribe(() => {
      this.print(device, payload);
    }, (error: any) => {
      this.errorPrintHandler(error);
    });
  }

  private print(device: BluetoothDevice, payload: string): void {
    this.busyService.setBusy(
      true,
      this.translateService.instant('Printing...')
    );
    this.cdr.markForCheck();

    this.btPlugin.action({
      command: 'write',
      device: device,
      writeData: payload,
    })
    .pipe(
      mergeMap((response: any) => {
        if (!this.singlePrint) return of(response);
        if (this.printerDevices.some(x => x.id === this.singlePrintDevice.id)) return of(response);

        return this.btPlugin.action({
          command: 'disconnect',
          device: device,
          unpair: true,
        })
        .pipe(
          map(() => {
            return response;
          })
        );
      }), catchError((error: any) => {
        if (!this.singlePrint) return throwError(error);
        if (this.printerDevices.some(x => x.id === this.singlePrintDevice.id)) return throwError(error);

        return this.btPlugin.action({
          command: 'disconnect',
          device: device,
          unpair: true,
        })
        .pipe(
          map(() => {
            return throwError(error);
          })
        );
      }),
    ).subscribe((response: any) => {
      this.printResponse = response === 'OK' ? undefined : response;
      this.successPrintHandler(this.printResponse?.content?.tid ? 'PrintedRfid' : 'Printed');
    }, (error: any) => {
      this.errorPrintHandler(error);
    });
  }

  private successPrintHandler(portName: string) {
    this.printed = true;
    this.busyService.setBusy(false);

    this.noUserInteractionHandler(portName);

    this.cdr.markForCheck();
  }

  private errorPrintHandler(error: any) {
    this.printed = false;
    this.failedPrintReason = error?.errorMessage || (typeof error === 'string' ? error : JSON.stringify(error));
    this.busyService.setBusy(false);

    this.appService.setStatusBarMessage(this.failedPrintReason);
    this.notificationService.showNotification(new Notification({
      title: this.translateService.instant('Notification'),
      text: this.translateService.instant('Print error:') + ' ' + this.failedPrintReason,
      type: RuntimeLayoutNotifyType.Alert
    }));

    this.noUserInteractionHandler('Failed');

    this.cdr.markForCheck();
  }

  private noUserInteractionHandler(portName: string) {
    if (
      !this.layoutControl.parseRV('UserInteraction') &&
      !this.layoutControl.parseRV('ScanVerification')
    ) {
      const eventContextValues: any = {};
      eventContextValues['PortName'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(portName),
        valueTypeId: RuntimeLayoutValueType.String
      });

      this.triggerEvent.emit({
        eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
        platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
      });
    }
  }

  runScanVerification() {
    const scanValue = this.controlVerificationComponent ? RuntimeLayoutValue.parse((this.controlVerificationComponent.getControlContext() || {}).TextBox) : undefined;
    if (scanValue === this.layoutControl.parseRV('ScanVerificationValue')) {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.translateService.instant('Scan verified successfuly.'),
        type: RuntimeLayoutNotifyType.Confirmation
      }));

      this.triggerEvent.emit({
        platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton,
      });
    } else {
      this.notificationService.showNotification(new Notification({
        title: this.translateService.instant('Notification'),
        text: this.layoutControl.parseRV('ScanVerificationText'),
        type: RuntimeLayoutNotifyType.Alert
      }));
    }
  }

  private parseAllergens(source: string): string {
    while(true) {
      if(source.indexOf('#') < 0) {
        break;
      }

      let allergen = source.substring(source.indexOf('#') + 1);

      if(allergen.indexOf('#') < 0) {
        break;
      }

      let updatedAllergen = '';
      allergen = allergen.substring(0, allergen.indexOf('#'));

      for(let i = 0; i < allergen.length; i++) {
        let charCode = allergen.charCodeAt(i);
        let hexCode = charCode.toString(16).toUpperCase();

        if(allergen[i] != " "){
          let allergenCode = "\\u40" + hexCode;
          updatedAllergen += allergenCode;
        }
        else {
          updatedAllergen += " ";
        }
      }

      source = source.replace(new RegExp('#' + allergen + '#', 'g'), updatedAllergen);
    }

    source = source.replace(new RegExp('å', 'g'), '\u00e5');
    source = source.replace(new RegExp('ä', 'g'), '\u00e4');
    source = source.replace(new RegExp('ö', 'g'), '\u00f6');
    source = source.replace(new RegExp('Å', 'g'), '\u00c5');
    source = source.replace(new RegExp('Ä', 'g'), '\u00c4');
    source = source.replace(new RegExp('Ö', 'g'), '\u00d6');

    return source;
  }

}
