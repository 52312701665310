import { Field, Message, Type } from 'protobufjs/light';
import { RuntimeLayoutSmartImageRegion } from './runtime-layout-smart-image-region.model';
import encoding from 'text-encoding';
const pako = require('pako');


@Type.d('RuntimeLayoutSmartImage')
export class RuntimeLayoutSmartImage extends Message<RuntimeLayoutSmartImage> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'bool', 'optional')
  active: boolean;
  @Field.d(3, 'int64', 'required')
  tick: number;

  @Field.d(10, 'string', 'optional')
  smartImageGuidId: string;
  @Field.d(11, 'string', 'optional')
  name: string;
  @Field.d(12, 'bool', 'optional')
  allowFreePoint: boolean;

  // @Field.d(50, 'bytes', 'optional')
  // smartImageBinary: Uint8Array;
  @Field.d(60, RuntimeLayoutSmartImageRegion, 'repeated')
  regions: RuntimeLayoutSmartImageRegion[];

  @Field.d(72, 'string', 'optional')
  resourceGuidId: string;
  @Field.d(73, 'int64', 'optional')
  resourceTick: number;

}
