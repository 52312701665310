import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RuntimeLayout, RuntimeLayoutScreen, RuntimeLayoutSnapshot } from '../../models';
import { AppService, DebugService, VibrationService } from '../../services';
import { TranslateService } from '../../services/app';
import { LayoutChangeActiveScreenService } from '../../services/protobuf/layout-change-active-screen.service';
import { DictUtils, LogUtils } from '../../utils';
import { BaseComponent } from '../base/base.component';



@Component({
  selector: 'lc-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent {

  @Input() layoutSnapshot: RuntimeLayoutSnapshot;

  @Input() set layout (value: RuntimeLayout) {
    this._layout = value;

    this.refresh();
  }
  get layout(): RuntimeLayout {
    return this._layout;
  }
  private _layout: RuntimeLayout;

  @Input() hideMenu: boolean;
  @Output() layoutChange = new EventEmitter<RuntimeLayout>();

  activeScreen: RuntimeLayoutScreen;
  layoutScreens: RuntimeLayoutScreen[];

  environmentName: string;
  isBatteryLow: boolean;
  isConnectionToServerActive: boolean;
  isTabMenuOpened: boolean;
  statusBarMessage: string;

  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private debugService: DebugService,
    private layoutChangeActiveScreenService: LayoutChangeActiveScreenService,
    private translateService: TranslateService,
    private vibrationService: VibrationService,
  ) {
    super();

    this.layoutScreens = [];
    this.statusBarMessage = '';

    this.subscriptions.push(
      this.appService.isBatteryLow()
      .subscribe((isBatteryLow: boolean) => {
        this.isBatteryLow = isBatteryLow;
        this.cdr.markForCheck();
      }),
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      }),
      this.appService.listenToStatusBarMessageChanges()
      .subscribe((msg: string) => {
        if (msg) LogUtils.warn(msg);

        this.statusBarMessage = msg;
        this.cdr.markForCheck();
      }),
    );
  }

  refresh() {
    if (!this.layout) return;

    this.activeScreen = this.layout.layoutScreens[this.layout.activeScreen];

    this.layoutScreens = DictUtils.dictToArray(this.layout.layoutScreens);

    const de = this.appService.getDeviceEnrollment();
    this.environmentName = de?.solutionEnvironmentOverrideShow && de?.solutionEnvironmentName
    ? de.solutionEnvironmentName
    : undefined;

    this.cdr.markForCheck();
  }

  setActiveScreen(objectId: number) {
    this.vibrationService.vibrate();

    if (this.layout) {
      this.layoutChangeActiveScreenService.trigger(this.layoutSnapshot, objectId)
      .subscribe(() => {

      }, (error: any) => {
        LogUtils.error('setActiveScreen.trigger() error:', error);
      });
    } else {
      LogUtils.error('setActiveScreen() with this.layout = undefined...how did this happen?');
      setTimeout(() => {
        this.debugService.sendAppStatus().subscribe();
      }, 1000);
    }

    this.isTabMenuOpened = false;

    this.refresh();
  }

  getScreenTitle(screen: RuntimeLayoutScreen) {
    let title = this.translateService.instant('Screen') + ' ' + screen.objectId;
    if (screen && screen.renderValues) {
      title = screen.parseRV('Text', '');
      const extraText = screen.parseRV('ExtraText', '');

      title = title + (extraText ? ' - ' + extraText : '');

      return title;
    }
  }

}

