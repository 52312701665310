import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RuntimeLayoutDebugLog } from '../../models/runtime-layout/debug';
import { DateUtils, RingBuffer } from '../../utils';
import { LocalSettingsService } from '../local-settings/local-settings.service';
import { LayoutDebugLogService } from '../protobuf';
import { AppService } from '../app';


export abstract class BasePlugin {

  private readonly ringBufferSize = 30;
  private static readonly storageEntryMaxLength = 1000;

  private appService: AppService;
  private layoutDebugLogService: LayoutDebugLogService;
  private settingsService: LocalSettingsService;

  protected pluginName: string;
  protected logRingBuffer: RingBuffer<string>;

  constructor(
    private injector: Injector,
  ) {
    this.logRingBuffer = new RingBuffer(this.ringBufferSize);

    this.appService = this.injector.get(AppService);
    this.layoutDebugLogService = this.injector.get(LayoutDebugLogService);
    this.settingsService = this.injector.get(LocalSettingsService);
  }

  abstract isPluginAllowed(): boolean;

  abstract initialize(options?: any): Observable<any>;

  abstract action(options?: any): Observable<any>;

  abstract start(callback?: (data: any) => void, options?: any): void;

  abstract stop(options?: any): void;

  abstract status(): Observable<any>;

  protected log(msg: string) {
    const logDateTime = DateUtils.nowAsLogString()
    if ((msg || '').length > BasePlugin.storageEntryMaxLength) {
      msg = msg.substring(0, BasePlugin.storageEntryMaxLength) + '\r\n(...)';
    }
    const logMsg = `[${logDateTime}] ${msg || ''}`;
    if (!environment.production) {
      console.log((this.pluginName || '') + ' ' + logMsg);
    }
    this.logRingBuffer.push(logMsg);

    if (!this.appService.isAppOnline) return;
    if (!this.settingsService.get().runDeviceDebug) return;

    this.logRingBuffer.maxSize = 100;

    this.layoutDebugLogService.trigger([
      new RuntimeLayoutDebugLog({
        logDateTime: logDateTime,
        method: this.pluginName || '',
        message: msg,
      })
    ]).subscribe();
  }

}
