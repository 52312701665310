import { Injectable, Injector } from '@angular/core';
import { Ndef, NdefEvent, NdefRecord, NdefTag, NFC } from '@ionic-native/nfc/ngx';
import { Platform } from '@ionic/angular';
import { from, Observable, of, Subject, Subscription, zip, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BrowserUtils, LogUtils } from '../../../utils';
import { ScannerService } from '../../scanner/scanner.service';
import { BasePlugin } from '../base-plugin';




export interface NfcPluginSettings {
  retryIntervalInMs: number;
  retryAttempts: number;
  readDelimiter: string;
}


@Injectable({
  providedIn: 'root'
})
export class NfcPlugin extends BasePlugin {

  private isPluginAllowedChecked: boolean;
  private msgToWrite: any[];
  private msgToWriteSubject: Subject<void>;
  private settings: NfcPluginSettings;
  private startSubscription: Subscription;

  constructor(
    injector: Injector,
    private nfc: NFC,
    private ndef: Ndef,
    private platform: Platform,
    private scannerService: ScannerService,
  ) {
    super(injector);

    this.pluginName = 'NfcPlugin';

    this.settings = {
      retryIntervalInMs: 3000,
      retryAttempts: 3,
      readDelimiter: undefined,
    };
  }

  isPluginAllowed(): boolean {
    return BrowserUtils.isDeviceApp() && !this.platform.is('android');
  }

  initialize(options?: any): Observable<void> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    }

    Object.assign(this.settings, options || {});
    return of(null);
  }

  action(options?: any): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of(null);
    } else {
      this.log(`Action '${options.command}' started...`);
      switch(options.command) {
        case 'discover':
          return of(null);
        case 'write':
          this.msgToWriteSubject = new Subject();

          this.msgToWrite = [this.ndef.textRecord(options.writeData)];

          return this.msgToWriteSubject.asObservable();
      }
    }
  }

  start(callback?: (data: any) => void, options?: any): void {
    Object.assign(this.settings, options || {});

    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return;
    } else {
      if (this.startSubscription) {
        this.startSubscription.unsubscribe();
        this.startSubscription = null;
      }

      from(this.nfc.enabled())
      .subscribe(() => {
        this.log('Starting NFC ndef listener.');
        this.startSubscription = this.nfc.addNdefListener(() => {
          this.log('Successfully attached ndef listener.');
        }, (err: any) => {
          this.log('Error attaching ndef listener: ' + JSON.stringify(err));
        }).subscribe((event: NdefEvent) => {
          if (this.msgToWrite) {
            from(this.nfc.write(this.msgToWrite))
            .subscribe(() => {
              this.msgToWriteSubject.next();
              this.msgToWriteSubject.complete();
            }, (error: any) => {
              this.msgToWriteSubject.error(error);
            })
          } else {
            this.handleNfcTagData(event.tag);
          }
        }, (error: any) => {
          this.log('addNdefListener error: ' + JSON.stringify(error));
        });
      }, (error: any) => {
        // do nothing...
      });
    }
  }

  private handleNfcTagData(tag: NdefTag) {
    this.log('Received ndef message. the tag contains: ' + JSON.stringify(tag));
    const tagId = this.nfc.bytesToHexString(tag.id);
    const msgs = (tag.ndefMessage || []).map((record: NdefRecord) => {
      return this.ndef.textHelper.decodePayload(record.payload);
    });
    const msgString = msgs.join(', ');

    this.log('Decoded tag id: ' + tagId);
    this.log('Decoded tag msgs: ' + msgString);

    let b64TagContent = msgString;
    try {
      b64TagContent = btoa(msgString);
    } catch(error) {
      LogUtils.error(b64TagContent);
    }

    this.scannerService.emitScan({
      source: 'NFC Reader',
      tagId: tagId,
      tagContent: b64TagContent,
      valueType: tag.type,
    });
  }

  stop(options?: any): void {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return;
    } else {
      // keep the subscription open as we want to allow the user to "keep scanning"
      // ofc he'll get an error if the current control doesn't accept scanning
      /*if (this.startSubscription) {
        this.startSubscription.unsubscribe();
        this.startSubscription = null;
      }*/
    }
  }

  status(): Observable<any> {
    if (!this.isPluginAllowed()) {
      if (!this.isPluginAllowedChecked) this.log('Cordova not available...');
      this.isPluginAllowedChecked = true;
      return of('Cordova not available...');
    } else {
      return zip(
        this.fromPromiseToSuccess(this.nfc.enabled()),
      ).pipe(
        map(([enabled]: any) => {
          return {
            enabled: enabled,
            log: Array.from(this.logRingBuffer),
          };
        })
      );
    }
  }

  private fromPromiseToSuccess(promise: Promise<any>): Observable<any> {
    return from(promise)
    .pipe(
      catchError((error: any) => {
        return of(error);
      })
    )
  }

}
