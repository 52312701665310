import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TestPrinterPopover } from './test-printer.popover';



@NgModule({
  declarations: [
    TestPrinterPopover,
  ],
  entryComponents: [
    TestPrinterPopover
  ],
  imports: [
    SharedModule,
  ],
})
export class TestPrinterPopoverModule { }
