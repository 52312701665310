import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ControlDimension1Component } from './control-dimension1.component';
import { ControlInput1Module } from '../input1/control-input1.module';


@NgModule({
  declarations: [
    ControlDimension1Component,
  ],
  exports: [
    ControlDimension1Component,
  ],
  imports: [
    SharedModule,
    ControlInput1Module,
  ],
  providers: [],
})
export class ControlDimension1Module { }
