import { addDays, addWeeks, differenceInSeconds, format, getDayOfYear, getISOWeek, getYear, intervalToDuration, startOfDay } from 'date-fns';

export class DateTimeIndexUtils {

  static decodeSecondsIndexToDate(date: Date, secondsIndex: number): Date {
    const dateString = format(date, 'yyyy-MM-dd');

    return new Date(dateString + 'T' + DateTimeIndexUtils.decodeSecondsIndexToTimeString(secondsIndex) + '.000Z');
  }

  static decodeSecondsIndexToTimeString(secondsIndex: number): string {
    if ((secondsIndex || 0) <= 0) return '00:00:00';

    const duration = intervalToDuration({ start: 0, end: secondsIndex * 1000 });
    return `${DateTimeIndexUtils.twoDigit(duration.hours)}:${DateTimeIndexUtils.twoDigit(duration.minutes)}:${DateTimeIndexUtils.twoDigit(duration.seconds)}`;
  }

  static encodeDateToSecondsIndex(date: Date): number {
    if (!date) return -1;
    if (date instanceof Date && isNaN(date.getTime())) return -1;

    return differenceInSeconds(date, startOfDay(date));
  }

  static decodeDayIndexToDate(dayIndex: number): Date {
    var day = dayIndex % 500;
    var year = (dayIndex - day) / 500;

    if (day == 0) { // day = 0 shouldn't even exists?!?!?
      throw (new Error('Not a correctly structured dayIndex!'));
    }

    var dateTime = addDays(new Date(year, 0, 1), day - 1);
    return dateTime;
  }

  static encodeDateToDayIndex(date: Date): number {
    var year = getYear(date);
    var day = getDayOfYear(date);

    return year * 500 + day;
  }

  static decodeWeekIndex(weekIndex: number): Date {
    const year = ~~(weekIndex / 100);
    const weekNumber = weekIndex % 100;
    let date = new Date(year, 0, 1);
    return addWeeks(date, weekNumber - 1);
  }

  static encodeWeekNumberToWeekIndex(year: number, weekNumber: number, weekMonthIndex: number) {
    if (weekNumber >= 52 && weekMonthIndex === 0) {
      year = year - 1;
    } else if (weekNumber === 1 && weekMonthIndex === 11) {
      year = year + 1;
    }

    return (year * 100) + weekNumber;
  }

  static encodeDateToWeekIndex(startDate: Date): number {
    let year = startDate.getFullYear();
    const weekNumber = getISOWeek(startDate);
    if (weekNumber >= 52 && startDate.getMonth() === 0) {
      year = startDate.getFullYear() - 1;
    } else if (weekNumber === 1 && startDate.getMonth() === 11) {
      year = startDate.getFullYear() + 1;
    }

    return (year * 100) + weekNumber;
  }

  private static twoDigit(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }
}
