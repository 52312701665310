import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChecklistMenuPopover } from './checklist-menu.popover';



@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    ChecklistMenuPopover,
  ],
  entryComponents: [
    ChecklistMenuPopover
  ]
})
export class ChecklistMenuPopoverModule { }
