import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared.module';
import { KeyboardComponent } from './components';


@NgModule({
  imports: [
    IonicModule,
    SharedModule,
  ],
  declarations: [
    KeyboardComponent,
  ],
  providers: [
  ],
  exports: [
    KeyboardComponent,
  ]
})
export class KeyboardModule { }
