export const themes = {
  light: {
    primary:    '#0072bc',
    secondary:  '#5b9bd5',
    tertiary:   '#65D5FF',

    success:    '#84BA1E',
    warning:    '#FFB200',
    danger:     '#f53d3d',

    light:      '#f4f4f4',
    // medium:  '#FCD0A2',
    dark:       '#444444',

    background: '#7fd9fa',

    black:      '#000000',
    gray:       'gray',
    white:      '#ffffff',
  },
  dark: {
    primary:    '#274F82',
    secondary:  '#5E95AA',
    tertiary:   '#43AAED',

    success:    '#84BA1E',
    warning:    '#FFB200',
    danger:     '#fc6868',

    light:      '#f4f4f4',
    // medium:  '#FCD0A2',
    dark:       '#333333',

    background: '#4d4b4c',

    black:      '#000000',
    gray:       'gray',
    white:      '#ffffff',
  },
};

