import { Field, MapField, Message, Type } from 'protobufjs/light';
import { DictString } from '../../dict.model';
import { RuntimeLayoutValue } from '../runtime-layout-value.model';

@Type.d('RuntimeLayoutSetData')
export class RuntimeLayoutSetData extends Message<RuntimeLayoutSetData> {

  @Field.d(1, 'int64', 'required')
  objectId: number;
  @Field.d(2, 'int64', 'required')
  tick: number;

  @MapField.d(10, 'string', RuntimeLayoutValue)
  setComplexDataValues: DictString<RuntimeLayoutValue>;

  parseRV(key: string, defaultValue?: any): any {
    if (!this.setComplexDataValues?.[key]) return null;

    const value = this.setComplexDataValues[key].parse();
    return value != null ? value : defaultValue;
  }
}
