import { Injectable } from '@angular/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Platform } from '@ionic/angular';
import { from, Observable, Subject } from 'rxjs';
import { LogUtils } from '../../utils';

interface Sound {
  key: string;
  asset: string;
  isNative: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private sounds: Sound[];
  private audioPlayer: HTMLAudioElement;
  private forceWebAudio: boolean;
  private webAudioPromise: Promise<void>;

  constructor(
    private platform: Platform,
    private nativeAudio: NativeAudio,
  ){
    this.sounds = [];
    this.audioPlayer = new Audio();
    this.forceWebAudio = true;
  }

  preload(keyAssetPair: any, subject?: Subject<void>): Observable<void> {
    subject = subject || new Subject<void>();

    let keys = Object.keys(keyAssetPair || {});
    if (keys && keys.length) {
      const key = keys[0];
      const asset = keyAssetPair[key];
      delete keyAssetPair[key];

      if (this.platform.is('cordova') && !this.forceWebAudio){
        from(this.nativeAudio.preloadSimple(key, asset))
        .subscribe((result) => {
          // console.log(result)
        }, (error: any) => {
          // console.log(error);
        }, () => {
          this.sounds.push({
            key: key,
            asset: asset,
            isNative: true
          });

          this.preload(keyAssetPair, subject);
        });
      } else {
        this.sounds.push({
          key: key,
          asset: asset,
          isNative: false
        });

        this.preload(keyAssetPair, subject);
      }
    } else {
      setTimeout(() => {
        subject.next();
        subject.complete();
      });
    }

    return subject.asObservable();
  }

  play(key: string): void {
    const soundToPlay = this.sounds.find((sound) => {
      return sound.key === key;
    });

    if (soundToPlay) {
      if (soundToPlay.isNative){
        this.nativeAudio.play(soundToPlay.asset);
      } else {
        if (!this.webAudioPromise) {
          this.audioPlayer.src = soundToPlay.asset;
          this.audioPlayer.load();
          try {
            this.webAudioPromise = this.audioPlayer.play()
            .then(() => {
              this.webAudioPromise = undefined;
            })
            .catch((error: any) => {
              LogUtils.warn('audioPlayer.play() was prevented: ' + error);
              this.webAudioPromise = undefined;
            });
          } catch (error) {
            // for some reason in Firefox sometimes play() returns:
            // TypeError: this.audioPlayer.play(...) is undefined
            // no idea if re-instantiating the Audio element fixes it or not as I couldn't repro the issue.
            this.webAudioPromise = undefined;
            this.audioPlayer = new Audio();
          }
        } else {
          setTimeout(() => {
            this.play(key);
          }, 50);
        }
      }
    }
  }

}