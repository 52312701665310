<div class="popover-confirm-wrapper">
  <h2 class="title">
    {{ title }}
  </h2>

  <p class="text"
    [innerHTML]="text">
  </p>

  <br>

  <div *ngIf="noText && yesText"
    class="flex-row">
    <ion-button class="flex-1 ion-no-margin"
      (click)="close(false)"
      fill="clear">
      {{ noText }} {{ noTimeoutInSec ? ' (' + noTimeoutInSec + ')' : '' }}
    </ion-button>
    <ion-button class="flex-1 ion-no-margin"
      (click)="close(true)"
      fill="clear">
      {{ yesText }}
    </ion-button>
  </div>

  <ion-toolbar *ngIf="!noText && !yesText"
    class="ion-no-padding">
    <nav>
      <ul>
        <li>
          <a href="javascript:void(0);"
            class="cancel"
            (click)="close(false)">
            {{ 'No' | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:void(0);"
            class="confirm"
            (click)="close(true)">
            {{ 'Yes' | translate }}
          </a>
        </li>
      </ul>
    </nav>
  </ion-toolbar>
</div>
