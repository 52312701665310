import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { PlatformResource } from 'src/app/shared/models/platform-resource.model';
import { CaseUtils, LogUtils } from 'src/app/shared/utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictString, RuntimeLayoutData, RuntimeLayoutDesign, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValue } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';


@Component({
  selector: 'lc-control-menu1',
  templateUrl: 'control-menu1.component.html',
  styleUrls: ['./control-menu1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlMenu1Component extends ControlBaseComponent implements OnInit {

  readonly LABEL_GUID: string = '1af7e2dc159a4b01b346db0734a1f502';

  @Input() layoutDesigns: RuntimeLayoutDesign[];

  menuDefinition: any;
  menuData: RuntimeLayoutData[];

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.menuData = [];

    if (!this.layoutControl || !this.layoutScreen) return;

    // Get menu definition (for the GRID CSS)
    this.menuDefinition = JSON.parse(this.layoutControl.parseRV('MenuStyleJson', '{}'));

    this.populateMenuData();
  }

  private populateMenuData(): void {
    const setId = this.layoutControl.parseRV('Set');

    if (this.layoutScreen.sets && this.layoutScreen.sets[setId]) {
      for (const dataObjectId of Object.keys(this.layoutScreen.sets[setId].datas || {})) {
        this.menuData.push(this.layoutScreen.datas[dataObjectId]);
      }

      // sort buttons according to menuDefinition (if any)
      const menuDefinitionItemsArray = (this.menuDefinition.items || []).map((item: any) => {
        return item.guidId.replace(/-/g, '');
      });
      if (menuDefinitionItemsArray?.length) {
        this.menuData = this.menuData.slice(0).sort((a, b) => {
          return menuDefinitionItemsArray.indexOf(a.dataGuidId) - menuDefinitionItemsArray.indexOf(b.dataGuidId);
        });
      }

      // parse the PlatformResource icons if any
      const menuJson = CaseUtils.toCamel(JSON.parse(this.layoutControl.parseRV('MenuJson', '{}')));
      this.menuData = (this.menuData || []).map((b: any) => {
        const existingButton = (menuJson.buttons || []).find(x => GuidUtils.isEqual(x.buttonGuidId, b.dataGuidId));
        if (existingButton?.resourceId) {
          b.icon = PlatformResource.resourceMap[existingButton?.resourceId];
        }
        return b;
      });
    } else {
      LogUtils.warn(`populateMenuData() - layoutScreen.sets not found for ${setId}...`);
    }
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    return null;
  }

  buttonClick(platformObjectGuidId: string) {
    this.vibrationService.vibrate();

    const platformObjectType = RuntimeLayoutEventPlatformObjectType.Menu;
    this.triggerEvent.emit({
      platformObjectType: platformObjectType,
      platformObjectGuidId: platformObjectGuidId,
    });
  }

}

