import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { BrowserUtils, LogUtils } from 'src/app/shared/utils';
import { DictString, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutValue, RuntimeLayoutValueType } from '../../../models';
import { ControlBaseComponent } from '../base/control-base.component';

class NativeExternalSetup {
  autoOpen: boolean;
  externalUrl: string;
  androidPackageName: string;
  iosUriScheme: string;
}

@Component({
  selector: 'lc-control-external1',
  templateUrl: 'control-external1.component.html',
  styleUrls: ['./control-external1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlExternal1Component extends ControlBaseComponent implements OnInit, OnDestroy {

  nativeExternalSetup: NativeExternalSetup;
  theme: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private iab: InAppBrowser,
    injector: Injector,
    private platform: Platform,
  ) {
    super(injector);

    this.theme = this.localSettingsService.get().theme;
  }

  ngOnInit() {
    this.nativeExternalSetup = JSON.parse(this.layoutControl.parseRV('NativeExternalSetupJson', '{}'));

    if (!this.nativeExternalSetup.autoOpen) return;

    this.openExternal();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openExternal() {
    LogUtils.log('openExternal()', this.nativeExternalSetup);

    const waitUntilDone = this.layoutControl.parseRV('WaitUntilDone') && this.platform.is('cordova');
    if (this.platform.is('android') && this.nativeExternalSetup.androidPackageName) {
      this.iab.create(
        'android-app://' + this.nativeExternalSetup.androidPackageName,
        '_system',
      );
      if (!waitUntilDone) this.continueWithPort('Completed');
    } else if (this.platform.is('ios') && this.nativeExternalSetup.iosUriScheme) {
      this.iab.create(
        this.nativeExternalSetup.iosUriScheme,
        '_system',
      );
      if (!waitUntilDone) this.continueWithPort('Completed');
    } else if (this.nativeExternalSetup.externalUrl) {
      const browser = this.iab.create(
        this.nativeExternalSetup.externalUrl,
        waitUntilDone ? '_blank' : '_system',
        { toolbarcolor: '#0072bc', toolbartranslucent: 'no' },
      );
      if (!waitUntilDone) {
        this.continueWithPort('Completed');
        return;
      }

      if (!browser?.on) return;

      browser.on('loaderror')?.subscribe((ev: any) => {
        LogUtils.error('openExternal() error ' + ev?.code + ': ' + ev?.message);
        browser.close();
        this.continueWithPort('Failed');
      });
      browser.on('exit')?.subscribe((ev: any) => {
        this.continueWithPort('Completed');
      });
    }
  }

  private continueWithPort(portName: 'Completed' | 'Failed') {
    const eventContextValues: any = {};
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    });
    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const context: any = {};

    return context;
  }

}

