import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Autostart } from '@ionic-native/autostart/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { Subscription, from, of, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { oem } from 'src/environments/oem';
import { LoadPopover } from './popovers';
import { RuntimeLayoutLoad, Settings } from './shared/models';
import { RuntimeLayoutNativeKeyboard } from './shared/models/runtime-layout/runtime-layout-native-keyboard.enum';
import { ApiService, AppService, AudioService, Busy, BusyService, LayoutDebugLogService, LocalSettingsService } from './shared/services';
import { StorageService } from './shared/services/app/storage.service';
import { BrowserUtils, LogUtils } from './shared/utils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  busy: Busy;
  isNewEnrollmentPage: boolean;
  loadPopover: HTMLIonPopoverElement;
  loadPopoverSubscription: Subscription;
  previousLayoutLoadTick: number;
  readonly: boolean;
  settings: Settings;
  hostStyleHack: Partial<CSSStyleDeclaration>;

  constructor(
    private apiService: ApiService,
    private appService: AppService,
    private audioService: AudioService,
    private autostart: Autostart,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef,
    private device: Device,
    private keyboard: Keyboard,
    private layoutDebugLogService: LayoutDebugLogService,
    private localSettingsService: LocalSettingsService,
    private ngZone: NgZone,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storageService: StorageService,
  ) {
    this.busy = {} as Busy;

    LogUtils.initialize(this.appService, this.layoutDebugLogService, this.localSettingsService, this.storageService);

    this.initCordova();
  }

  @HostListener('window:resize', ['$event'])
  resize(event) {
    // HACK: on screen rotate (or resize) the page size wouldn't update and we would get a white section on the screen unusable
    this.hostStyleHack = undefined;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.hostStyleHack = { width: '100%', height: '100%' };
      this.cdr.markForCheck();
    }, 1);
  }

  private initCordova() {
    zip(
      from(this.platform.ready()),
      this.apiService.init(),
    )
    .pipe(
      catchError((error: any) => {
        console.error(error);
        return of(null);
      })
    ).subscribe((result: [string, void]) => {
      LogUtils.log('Cordova is initialized - platform: ' + result[0]);
      LogUtils.log('Device manufacturer: ' + (this.device.manufacturer || 'Web/Browser'));
      this.initApp();
    });
  }

  private initApp() {
    this.subscribeToBusyService();
    this.subscribeToReadonlyChanges();
    this.subscribeToLoadChanges();
    this.subscribeToSettingsService();

    this.registerBackButtonAction();
    this.registerKeyboardEvents();
    this.registerTimeSetCallback();
    this.preventNativeContextMenu();

    this.appService.initService();

    this.router.events.subscribe((ev: RouterEvent) => {
      if (ev instanceof NavigationEnd) {
        this.isNewEnrollmentPage = ev.url.indexOf('enroll-new') >= 0;
        this.cdr.markForCheck();
      }
    });

    if (BrowserUtils.isDeviceApp()) {
      if (this.platform.is('android')) {
        if (oem.autoStart) {
          this.autostart.enable();
        } else {
          this.autostart.disable();
        }
      }
    }

    if (this.platform.is('cordova')) {
      this.statusBar.overlaysWebView(false);
      this.statusBar.styleBlackOpaque();
      this.statusBar.backgroundColorByHexString('#254f83');
      this.splashScreen.hide();
    }

    setTimeout(() => {
      this.preloadAudio();

      this.appService.enrollAndInitDevice();
    });
  }

  private preloadAudio() {
    const keyAssetPair = {
      alert: 'assets/audio/alert.mp3',
      confirmation: 'assets/audio/confirmation.mp3',
      critical: 'assets/audio/critical.mp3',
      notify: 'assets/audio/notify.mp3',
      scan: 'assets/audio/scan.mp3',
      verification: 'assets/audio/verification.mp3',
      alertwav: 'assets/audio/alert.wav',
      confirmationwav: 'assets/audio/confirmation.wav',
      criticalwav: 'assets/audio/critical.wav',
      notifywav: 'assets/audio/notify.wav',
      scanwav: 'assets/audio/scan.wav',
      verificationwav: 'assets/audio/verification.wav',
    }
    this.audioService.preload(keyAssetPair).subscribe();
  }

  private subscribeToBusyService() {
    this.busyService.listenToBusyChanges()
    .subscribe((busy: Busy) => {
      this.ngZone.run(() => {
        this.busy = busy;

        this.cdr.markForCheck();
      });
    });
  }

  private subscribeToReadonlyChanges() {
    this.appService.readonlyChanges()
    .subscribe((readonly: boolean) => {
      this.readonly = readonly;

      this.cdr.markForCheck();
    });
  }

  private subscribeToLoadChanges() {
    this.appService.layoutLoadChanges()
    .subscribe((layoutLoad: RuntimeLayoutLoad) => {
      if (
        (!this.previousLayoutLoadTick && layoutLoad) ||
        (layoutLoad && this.previousLayoutLoadTick !== layoutLoad.tick)
      ) {
        this.previousLayoutLoadTick = layoutLoad.tick;
        this.showLoadPopover(layoutLoad);
      } else {
        this.hideLoadPopover();
      }

      this.cdr.markForCheck();
    });
  }

  private subscribeToSettingsService() {
    this.localSettingsService.listen()
    .subscribe((settings: Settings) => {
      this.settings = settings;

      this.cdr.markForCheck();
    });
  }

  private registerBackButtonAction() {
    this.platform.backButton.subscribe(() => {
      this.appService.backButtonClick();
    });
  }

  private registerKeyboardEvents() {
    this.keyboard.onKeyboardWillShow()
    .subscribe(() => {
      if (this.settings.keyboard === RuntimeLayoutNativeKeyboard.Native) return;

      console.warn('Native keyboard trying to open...');
      this.keyboard.hide();
    });
  }

  private registerTimeSetCallback(): void {
    if (!BrowserUtils.isDeviceApp() || !this.platform.is('android')) return;

    LogUtils.log('Registering to receive system date/time changes...');
    (window as any).plugins.intentShim.registerBroadcastReceiver({
      filterActions: [
        'android.intent.action.TIME_SET',
      ],
      filterCategories: [
        'android.intent.category.DEFAULT',
      ]},
      () => {
        this.appService.enrollAndInitDevice();
      }
    );
  }

  private preventNativeContextMenu() {
    if (this.platform.is('cordova')) {
      // prevent long-press context menu (copy, paste, select-all...) on mobile
      window.oncontextmenu = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return false;
      };
    }
  }

  private showLoadPopover(layoutLoad: RuntimeLayoutLoad) {
    if (this.loadPopover && this.loadPopover.component) {
      (this.loadPopover.component as any).setValue(layoutLoad.value);

      this.cdr.markForCheck();
    } else {
      this.loadPopoverSubscription = from(this.popoverCtrl.create({
        component: LoadPopover,
        componentProps: {
          text: layoutLoad.text,
          value: layoutLoad.value,
        },
        cssClass: `load`,
        backdropDismiss: false,
        showBackdrop: true
      }))
      .subscribe((popover: HTMLIonPopoverElement) => {
        if (this.loadPopoverSubscription) {
          this.loadPopover = popover;

          this.loadPopover.present();

          this.cdr.markForCheck();
        }
      });
    }
  }

  private hideLoadPopover() {
    if (this.loadPopoverSubscription) {
      this.loadPopoverSubscription.unsubscribe();
      this.loadPopoverSubscription = undefined;
    }

    if (this.loadPopover) {
      from(this.loadPopover.onDidDismiss())
      .subscribe(() => {
        this.loadPopover = undefined;

        this.appService.focusActiveControl();

        this.cdr.markForCheck();
      });
      this.loadPopover.dismiss();
    }
  }

  /*notification() {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then((registration) => {
        // Registration was successful
        LogUtils.log('ServiceWorker registration successful with scope: ', registration.scope);
        navigator.serviceWorker.ready.then((r) => {
          LogUtils.log('ServiceWorker is ready');
          r.showNotification('Vibration Sample', {
            body: 'Buzz! Buzz!',
            vibrate: environment.android.vibratePattern,
            tag: 'vibration-sample',
          }).then((r) => {
            LogUtils.log('Notification showed');
          }).catch((err: any) => {
            // registration failed :(
            LogUtils.log('ShowNotification failed: ', err);
          });
        }).catch((err: any) => {
          // registration failed :(
          LogUtils.log('ServiceWorker ready failed: ', err);
        });
      }, (err: any) => {
        // registration failed :(
        LogUtils.log('ServiceWorker registration failed: ', err);
      });


      from(Notification.requestPermission())
      .subscribe((permission: NotificationPermission) => {
        LogUtils.log('Notification Permission: ' + permission);

        if (permission === 'granted') {
          new Notification('test', {
            body: 'Hello world!',
            vibrate: environment.android.vibratePattern
          })
        }
      });
    }
  }*/

}
